import { Channels, PostTypes, UserTypes } from '@slc-libs/enums';

import { BankList } from '@slice-interfaces/bulk-payment/campaign';
import { HashtagCategory } from '@slice-interfaces/categories/category';
import { KycInterface } from '@slice-interfaces/profile/creator-profile';
import { USER_INVITE_STATUS } from '@slice-enums/user';

export interface AgencyClientsRosterDetail {
  fullName: string;
  companyName?: string;
  personalInfo: {
    email: string;
    phone?: string;
    address?: string;
    dateOfBirth?: string;
    city?: string;
    country?: string;
  };
  mediaKitUrl: string;
  favorited: boolean;
  rateCard: Array<{
    channel: Channels;
    postType: PostTypes;
    price: number;
  }>;
  creatorKyc?: KycInterface;
  creatorNotes: string;
  creatorUserId: string;
  status: USER_INVITE_STATUS;
  categories: Array<HashtagCategory>;
  pastPerformance: any;
  recentPartnership: any;
  agencyUserId?: string;
  avatar: string;

  // id: string;
  // userId: string;
  // avatar: string;
  // name: string;
  // role: UserTypes;
  // socialAccounts: Array<{
  //   channel: Channels;
  //   followers: number;
  // }>;
  // postPrices: Array<{
  //   channel: Channels;
  //   postType: PostTypes;
  //   price: number;
  // }>;
}

export interface AgencyClientsRosterItem {
  age: string;
  avatar?: string;
  creatorName: string;
  agencyUserId: string;
  categories: Array<HashtagCategory>;
  status: USER_INVITE_STATUS;
  followersSubscribers: number;
  invitationId: string;
  favorited: boolean;
  er: number;
  userId: string;
  creatorEmail?: string | null;
  channels: Array<Channels>;
  city?: string;
  companyName?: string | null;
  lastPaymentMethod: PaymentMethod | null;
  activePaymentMethod: PaymentMethod | null;
  bankDetail: BankList | null;
  userStatus?: string;
  bankAccountName?: string;
  bankAccountNumber?: string;
  /*id: string;
  agencyName: string;
  picName: string;
  picEmail: string;
  creatorName: string;
  agencyUser?: AgencyClientsRosterItemAgencyUser | undefined;
  status: AgencyInvitationStatus;*/
}

export interface AgencyClientPaymentInfo {
  paymentMethod?: PaymentMethod;
  bankCode: string | null;
  bankAccountName: string | null;
  bankAccountNumber: string | null;
}

export enum PaymentMethod {
  SLICE_WALLET = 'SLICE_WALLET',
  BANK_TRANSFER = 'BANK_TRANSFER',
}
