import { FormlyFormModel } from '@slice-interfaces/integration/formly-form.model';
import {
  MekariJurnalFeature,
  MekariJurnalTransaction,
} from '@slice-enums/integration/mekari-integration.enum';

/* region: Jurnal Form */
export function FormMekariGenerator(params: any): Array<FormlyFormModel<any>> {
  return [
    {
      key: MekariJurnalTransaction.JURNAL_CATEGORY,
      type: 'select',
      value: null,
      props: {
        label: 'Mekari Jurnal Category',
        placeholder: 'Select category',
        required: true,
        options: null as any,
        valueProp: 'value',
        labelProp: 'label',
        inputClass: 'mekari-icon-input',
      },
      onChange: {
        method: 'populateForm',
        params,
      },
    },
    {
      key: MekariJurnalTransaction.CASH_ACCOUNT,
      type: 'autocomplete',
      value: null,
      props: {
        label: 'Cash Account',
        placeholder: 'Select cash account',
        required: true,
        options: null as any,
        labelProp: 'label',
        inputClass: 'mekari-icon-input',
      },
    },
    {
      key: MekariJurnalTransaction.PAYMENT_METHOD,
      type: 'autocomplete',
      value: null,
      props: {
        label: 'Payment Method',
        placeholder: 'Select payment method',
        required: true,
        options: null as any,
        labelProp: 'label',
        inputClass: 'mekari-icon-input',
      },
    },
  ];
}

export const FormMekariPayment: Record<any, Array<FormlyFormModel<any>>> = {
  trigger: [
    {
      key: 'trigger',
      type: 'radio',
      value: null,
      props: {
        label: 'How do you want to record transactions',
        required: true,
        options: [
          {
            label: 'After Creating Campaign',
            description:
              'Entry will be recorded in Jurnal once a campaign begins as an <span class="font-underline">open transaction</span>',
            value: 'ON_CAMPAIGN_CREATED',
          },
          {
            label: 'After Sending Payments',
            description:
              'Entry will be recorded in Jurnal after payments are sent',
            value: 'ON_PAYMENT_SENT',
          },
        ] as any,
        valueProp: 'value',
        labelProp: 'label',
        inputClass: 'trigger-input',
      },
    },
  ],
  setting: [
    {
      key: 'setting',
      type: 'radio',
      value: null,
      props: {
        label: 'How do you want to categorize your creator payments',
        required: true,
        options: [
          {
            formParser: {
              fieldAPI: 'mekari-form',
              fieldParams: 'value',
            },
            label: 'Per Transaction',
            description:
              'Your payments to multiple creators will be consolidated into one entry per transaction in Mekari Jurnal',
            value: 'PER_TRANSACTION',
          },
          {
            formParser: {
              fieldAPI: 'mekari-form',
              fieldParams: 'value',
            },
            label: 'Per Content Creator',
            description:
              "Each creator's payment will be logged as a separate entry per-content creator in Mekari Jurnal",
            value: 'PER_CREATOR',
          },
        ] as any,
        valueProp: 'value',
        labelProp: 'label',
        inputClass: 'setting-input',
      },
      onChange: {
        method: 'populateForm',
        params: {
          feature: 'creatorPayment',
          objectName: 'setting',
        },
      },
    },
  ],
  config: [],
  feeConfig: [],
};

export const FormMekariPaymentS: Record<any, Array<FormlyFormModel<any>>> = {
  config: FormMekariGenerator({
    feature: MekariJurnalFeature.CREATOR_PAYMENT,
    objectName: 'config',
    trx: MekariJurnalTransaction.JURNAL_CATEGORY,
  }),
  feeConfig: FormMekariGenerator({
    feature: MekariJurnalFeature.CREATOR_PAYMENT,
    objectName: 'feeConfig',
    trx: MekariJurnalTransaction.JURNAL_CATEGORY,
  }),
};

export const FormMekariContract: Record<any, Array<FormlyFormModel<any>>> = {
  config: FormMekariGenerator({
    feature: MekariJurnalFeature.CONTRACT_METERAI,
    objectName: 'config',
    trx: MekariJurnalTransaction.JURNAL_CATEGORY,
  }),
  feeConfig: [],
};

export const FormMekariTopup: Record<any, Array<FormlyFormModel<any>>> = {
  config: [
    {
      key: MekariJurnalTransaction.SOURCE_ACCOUNT,
      type: 'autocomplete',
      value: null,
      props: {
        label: 'Source Cash Account',
        placeholder: 'Select cash account',
        hintKey:
          'Select source cash account. You can change it anytime during a transaction',
        required: true,
        options: null as any,
        labelProp: 'label',
        inputClass: 'mekari-icon-input',
      },
    },
    {
      key: MekariJurnalTransaction.DESTINATION_ACCOUNT,
      type: 'autocomplete',
      value: null,
      props: {
        label: 'Destination Cash Account',
        placeholder: 'Select cash account',
        hintKey:
          'Select destination cash account. You can change it anytime during a transaction',
        required: true,
        options: null as any,
        labelProp: 'label',
        inputClass: 'mekari-icon-input',
      },
    },
  ],
  feeConfig: FormMekariGenerator({
    feature: MekariJurnalFeature.BALANCE_TOPUP,
    objectName: 'feeConfig',
    trx: MekariJurnalTransaction.JURNAL_CATEGORY,
  }),
};
/* endregion */
/* region: Dynamic Hardcoded Value */
export const AdditionalFormBuy: FormlyFormModel<any> = {
  key: MekariJurnalTransaction.PRODUCT_ACCOUNT,
  type: 'autocomplete',
  value: null,
  props: {
    label: 'Product Buy Account',
    placeholder: 'Select product account',
    required: true,
    options: null as any,
    labelProp: 'label',
    inputClass: 'mekari-icon-input',
  },
};

export const AdditionalFormExpense: FormlyFormModel<any> = {
  key: MekariJurnalTransaction.EXPENSE_ACCOUNT,
  type: 'autocomplete',
  value: null,
  props: {
    label: 'Expense Account',
    placeholder: 'Select expense account',
    required: true,
    options: null as any,
    labelProp: 'label',
    inputClass: 'mekari-icon-input',
  },
};
/* endregion */

/* region: Dropdown Hardcoded Value */
export const OptionsJurnalCategory = [
  {
    formParser: {
      fieldAPI: 'mekari-form',
      fieldParams: 'value',
    },
    label: 'Purchase Invoice',
    value: 'PURCHASE',
  },
  {
    formParser: {
      fieldAPI: 'mekari-form',
      fieldParams: 'value',
    },
    label: 'Expense',
    value: 'EXPENSE',
  },
];
/* endregion */
