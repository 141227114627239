import { LANDING_PAGE_LINKS } from '@slc-libs/constants';
import { ENVIRONMENTS } from '@slc-libs/enums';

import { APP_VERSION, isAdminBuild } from './app-version';
import { Environment } from './environment.interface';

export const environment: Environment = {
  envType: ENVIRONMENTS.dev,
  production: true,
  isAdminBuild: isAdminBuild,
  isStage: false,
  blogUrl: 'https://api-dev.slice.id/webflow',
  nodeUrl: 'https://api-dev.slice.id/node',
  appVersion: APP_VERSION,
  uiHost: 'https://api-dev.slice.id',
  mixPanelToken: '0d783f9e47308df96d1a479f8d984ff2',
  landingPageLink: LANDING_PAGE_LINKS.dev,
  stripeKey:
    'pk_test_51M1M4UBLzYZdbtgbut0al5mQ4iJ7BnCcJsJpliY7J0ZsSHkulNOZlzKZALCm5u999gDx52TwmcanHBPnODP30tym004VgqpCfK',
};
