import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { UiStateService } from '@slice-services/ui-state.service';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import {
  AssetsType,
  NewAssetsType,
  TypeFileUpload,
} from '@slice-enums/assets/asset-type';

import { ASSETS_IG_IMG_REQUIREMENTS } from '../../../_const/assets-ig-image-requirements';
import { ASSETS_IG_VIDEO_REQUIREMENTS } from '../../../_const/assets-ig-video-requirements';

@Component({
  selector: 'slice-file-upload-default',
  templateUrl: './file-upload-default.component.html',
  styleUrls: ['./file-upload-default.component.scss'],
})
export class FileUploadDefaultComponent
  extends AbstractSubscriberComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() files: Array<File>;
  @Input() multiple: boolean;
  @Input() assetType?: AssetsType;
  @Input() newAssetType?: NewAssetsType;
  @Input() titleMessage?: string = 'Select or Drag-Drop File (PDF)';
  @Input() inputMessage?: string =
    'Please upload your signed contract with Agency for this campaign';
  @Input() type?: TypeFileUpload = TypeFileUpload.DEFAULT; //default, PDF

  private dragWrap: HTMLElement;
  public fileData: any[] = [];
  public isDragged = false;
  public maxSizeByte: number;
  public typesRestriction: any;

  @Output() filesSelected = new EventEmitter<Array<File>>();

  @ViewChild('pFileUpload') pFileUpload: FileUpload;

  constructor(
    private tS: TranslocoService,
    private elRef: ElementRef,
    public uiStateS: UiStateService,
    private mS: MessageService,
    private renderer: Renderer2,
  ) {
    super();
  }

  ngOnInit(): void {
    this.restrictSelectionByType();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    this.dragWrap = (
      this.elRef.nativeElement as HTMLElement
    ).getElementsByClassName('p-fileupload-content')[0] as HTMLElement;
    this.dragWrap.addEventListener('dragenter', () => {
      this.dragEnter();
    });
    this.dragWrap.addEventListener('dragleave', () => {
      this.dragLeave();
    });
    this.dragWrap.addEventListener('drop', () => {
      this.dragLeave();
    });
  }

  private restrictSelectionByType(): void {
    if (
      this.assetType === AssetsType.IG_IMAGE ||
      this.assetType === AssetsType.AVATAR
    ) {
      this.typesRestriction = 'image/*';
    } else if (this.assetType === AssetsType.IG_VIDEO) {
      this.typesRestriction = 'video/*';
    } else {
      if (this.newAssetType === NewAssetsType.PDF) {
        this.typesRestriction = '.pdf';
      } else {
        this.typesRestriction = '.pdf, image/*';
      }
    }
  }

  dragEnter(): void {
    this.isDragged = true;
  }

  dragLeave(): void {
    this.isDragged = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selected(event: any): void {
    // console.log(event, 'sadasda');
    const element = this.elRef.nativeElement.querySelector(
      '.p-fileupload-buttonbar',
    );
    const element2 = this.elRef.nativeElement.querySelector(
      '.p-fileupload-choose',
    );
    this.renderer.setStyle(element, 'width', '90%');
    this.renderer.setStyle(element, 'align-items', 'flex-end');
    this.renderer.setStyle(element, 'flex-direction', 'column');

    this.renderer.setStyle(element2, 'width', 'unset');
    this.renderer.setStyle(element2, 'right', '14px');

    for (const file of event.files) {
      this.fileData = [file];
      this.filesSelected?.emit([file]);
    }
    // if (this.assetType === AssetsType.IG_IMAGE) {
    //   this.checkIgImage(file);
    // } else if (this.assetType === AssetsType.IG_VIDEO) {
    //   this.checkIgVideo(file);
    // } else {
    //   this.filesSelected?.emit(e.currentFiles);
    // }
  }

  checkIgImageAspectRatio(
    width: number,
    height: number,
  ): { w: number; h: number } {
    const gcd = (a: number, b: number): number => {
      return b ? gcd(b, a % b) : a;
    };
    const divisor = gcd(width, height);
    return { w: width / divisor, h: height / divisor };
  }
}
