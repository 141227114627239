import { BriefProposalStatus } from '@slc-libs/enums';

type cssClasses =
  | ''
  | 'color-blue'
  | 'color-yellow'
  | 'color-red'
  | 'color-green'
  | 'color-purple';

export interface Config {
  translateKey: string;
  cssColorClass: cssClasses;
}
export const briefProposalMap: Record<BriefProposalStatus, Config> = {
  [BriefProposalStatus.PRE_SELECTED]: {
    translateKey: 'enums.statuses.creator.brief-proposal.PRE_SELECTED',
    cssColorClass: 'color-green',
  },
  [BriefProposalStatus.INVITED]: {
    translateKey: 'enums.statuses.creator.brief-proposal.INVITED',
    cssColorClass: 'color-green',
  },
  [BriefProposalStatus.CREATOR_ACCEPTED]: {
    translateKey: 'enums.statuses.creator.brief-proposal.CREATOR_ACCEPTED',
    cssColorClass: 'color-yellow',
  },
  [BriefProposalStatus.CREATOR_DECLINED]: {
    translateKey: 'enums.statuses.creator.brief-proposal.CREATOR_DECLINED',
    cssColorClass: '',
  },
  [BriefProposalStatus.BRAND_DECLINED]: {
    translateKey: 'enums.statuses.creator.brief-proposal.BRAND_DECLINED',
    cssColorClass: '',
  },
  [BriefProposalStatus.BRAND_ACCEPTED]: {
    translateKey: 'enums.statuses.creator.brief-proposal.BRAND_ACCEPTED',
    cssColorClass: 'color-yellow',
  },
  [BriefProposalStatus.SLICE_RECOMMENDED]: {
    translateKey: 'enums.statuses.creator.brief-proposal.SLICE_RECOMMENDED',
    cssColorClass: 'color-green',
  },
  [BriefProposalStatus.IN_PROGRESS]: {
    translateKey: 'enums.statuses.creator.brief-proposal.IN_PROGRESS',
    cssColorClass: 'color-blue',
  },
  [BriefProposalStatus.COMPLETED]: {
    translateKey: 'enums.statuses.creator.brief-proposal.COMPLETED',
    cssColorClass: '',
  },
  [BriefProposalStatus.ACTIVE]: {
    translateKey: 'enums.statuses.creator.brief-proposal.ACTIVE',
    cssColorClass: '',
  },
};
