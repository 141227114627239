export enum AvailableIntegration {
  MEKARI_JURNAL = 'mekari-jurnal',
  OPENAI = 'openai',
  STRIPE = 'stripe',
  ACCURATE = 'accurate',
  XERO = 'xero',
  SAP = 'sap',
  KLEDO = 'kledo',
  ORACLE = 'oracle',
  HARMONY = 'harmony',
  NETSUITE = 'netsuite',
}

export enum ExternalIntegrationStatus {
  // disconnected (config false)
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  NOT_CONNECTED = 'NOT_CONNECTED',

  // connected (config true)
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  EXPIRED = 'EXPIRED', // like disconnected or not_connected
  ACTIVE = 'ACTIVE',
}
