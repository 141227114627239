import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Channels, PostTypes } from '@slc-libs/enums';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

@Component({
  selector: 'slice-agency-reports-info-limit',
  templateUrl: './info-limit.component.html',
  styleUrls: ['./info-limit.component.scss'],
})
export class AgencyReportInfoLimitComponent
  extends AbstractSubscriberComponent
  implements OnChanges
{
  @Input() channel: Channels | undefined;
  @Input() showSpecialNotes? = false;
  public infoChannel: InfoChannelLimit[] = [];
  public infoLimit: InfoLimit[] = [];

  constructor() {
    super();
    this.infoChannel = InfoPostType;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.channel) this.changeChannel(this.channel);
  }

  changeChannel(channel: Channels) {
    const matchedData = this.infoChannel.find(v => v.channel === channel);
    if (matchedData) {
      this.infoLimit = matchedData.limit;
    } else {
      this.infoLimit = [];
    }
  }
}

interface InfoChannelLimit {
  channel: Channels;
  limit: InfoLimit[];
}

export interface InfoLimit {
  label: string;
  value: PostTypes;
  description: string;
  postype: number;
}

export const InfoPostType: InfoChannelLimit[] = [
  {
    channel: Channels.INSTAGRAM,
    limit: [
      {
        label: 'Reel',
        value: PostTypes.REELS,
        description: 'A Short Video',
        postype: 0,
      },
      {
        label: 'Story',
        value: PostTypes.STORIES,
        description: 'Single story up to 60secs',
        postype: 0,
      },
      /*{
        title: 'Story Session',
        value: PostTypes.STORY_SESSION,
        description: 'Up to 3 stories',
      },*/
      {
        label: 'Post',
        value: PostTypes.POST,
        description: 'Single photo in a post',
        postype: 0,
      },
      {
        label: 'Carousel',
        value: PostTypes.CAROUSEL_ALBUM,
        description: 'Up to 10 photos/video in a post',
        postype: 0,
      },
    ],
  },
  {
    channel: Channels.YOUTUBE,
    limit: [
      {
        label: 'Product Review',
        value: PostTypes.PRODUCT_REVIEW,
        description: 'Full video around product, min 10 minutes',
        postype: 0,
      },
      {
        label: 'Product Placement',
        value: PostTypes.PRODUCT_PLACEMENT,
        description: 'Product displayed within the episode, visible >3 min',
        postype: 0,
      },
      {
        label: 'Host Read Ad',
        value: PostTypes.HOST_READ_AD,
        description: '1-minute ad read before your episode',
        postype: 0,
      },
    ],
  },
  {
    channel: Channels.TIKTOK,
    limit: [
      {
        label: 'Video',
        value: PostTypes.TIKTOK_VIDEO,
        description: 'Up to 3 minutes video',
        postype: 0,
      },
    ],
  },
];
