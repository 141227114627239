import { ChannelPropsalStatus } from '@slc-libs/enums';

type cssClasses =
  | ''
  | 'color-blue'
  | 'color-yellow'
  | 'color-red'
  | 'color-green'
  | 'color-purple';

export interface Config {
  translateKey: string;
  cssColorClass: cssClasses;
}

export const channelProposalMap: Record<ChannelPropsalStatus, Config> = {
  [ChannelPropsalStatus.PRE_SELECTED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.PRE_SELECTED',
    cssColorClass: 'color-green',
  },
  [ChannelPropsalStatus.SCORED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.SCORED',
    cssColorClass: 'color-green',
  },
  [ChannelPropsalStatus.SLICE_INVITED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.SLICE_INVITED',
    cssColorClass: 'color-green',
  },
  [ChannelPropsalStatus.CREATOR_DECLINED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.CREATOR_DECLINED',
    cssColorClass: '',
  },
  [ChannelPropsalStatus.CREATOR_ACCEPTED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.CREATOR_ACCEPTED',
    cssColorClass: '',
  },
  [ChannelPropsalStatus.BRAND_DECLINED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.BRAND_DECLINED',
    cssColorClass: '',
  },
  [ChannelPropsalStatus.BRAND_ACCEPTED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.BRAND_ACCEPTED',
    cssColorClass: 'color-green',
  },
  [ChannelPropsalStatus.BRAND_APPROVED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.BRAND_APPROVED',
    cssColorClass: '',
  },
  [ChannelPropsalStatus.CHANGES_REQUESTED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.CHANGES_REQUESTED',
    cssColorClass: 'color-red',
  },
  [ChannelPropsalStatus.CONTENT_SUBMITTED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.SUBMITTED',
    cssColorClass: 'color-green',
  },
  [ChannelPropsalStatus.PUBLISHED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.SUBMITTED',
    cssColorClass: 'color-green',
  },
  [ChannelPropsalStatus.IN_PROGRESS]: {
    translateKey: 'enums.statuses.creator.channel-proposal.NOT_SUBMITTED',
    cssColorClass: 'color-yellow',
  },
  [ChannelPropsalStatus.PENDING_CREATOR_PUBLICATION]: {
    translateKey: 'enums.statuses.creator.channel-proposal.NOT_SUBMITTED',
    cssColorClass: 'color-yellow',
  },
  [ChannelPropsalStatus.SLICE_RECOMMENDED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.NOT_SUBMITTED',
    cssColorClass: 'color-yellow',
  },
  [ChannelPropsalStatus.WAITING_SUBMISSION]: {
    translateKey: 'enums.statuses.creator.channel-proposal.NOT_SUBMITTED',
    cssColorClass: 'color-yellow',
  },
  [ChannelPropsalStatus.INVALID_SUBMISSION]: {
    translateKey: 'enums.statuses.creator.channel-proposal.CREATOR_DECLINED',
    cssColorClass: '',
  },
  [ChannelPropsalStatus.SUBMITTED]: {
    translateKey: 'enums.statuses.creator.channel-proposal.PUBLISHED',
    cssColorClass: 'color-green',
  },
  // [ChannelPropsalStatus.PENDING_CREATOR_PUBLICATION]: {
  //   translateKey:
  //     'enums.statuses.creator.channel-proposal.PENDING_CREATOR_PUBLICATION',
  //   cssColorClass: 'color-green',
  // },
};
