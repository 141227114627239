<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="false"
  [dismissableMask]="true"
  (onHide)="fixEvent.emit(undefined)"
>
  <p-header>
    <div class="dialog-header">
      <span class="dialog-title">Import Creator > Map</span>
    </div>
  </p-header>

  <main>
    <div class="summary-section">
      <p>
        The file contains
        {{
          data.totalInvited +
            data.totalAddedToRoster +
            data.totalInvalidCreators
        }}
        data entries
      </p>
      <div class="summary-stats">
        <div>
          <i class="pi pi-check-circle text-success"></i>
          <span> {{ data.totalInvited }} creator successfully imported</span>
        </div>
        <div>
          <i class="pi pi-info-circle text-info"></i>
          <span>
            {{ data.totalAddedToRoster }} creator already in your roster</span
          >
        </div>
        <div>
          <i class="pi pi-exclamation-circle text-warning"></i>
          <span> {{ data.totalInvalidCreators }} creator failed to import</span>
        </div>
      </div>
    </div>

    <p class="instruction-text">
      Please check the format. Data may be invalid due to wrong format.
    </p>

    <table class="custom-table my-3" *ngIf="data?.invalidCreators">
      <thead>
        <tr>
          <th><span class="slc-flex-c-c">No</span></th>
          <th>Full Name</th>
          <th><span class="slc-flex-c-c">Number of Errors</span></th>
          <th><span class="slc-flex-c-c">Action</span></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let creator of data?.invalidCreators; let i = index">
          <!-- Status Column -->
          <td>
            <div class="slc-flex-c-c">
              <i
                *ngIf="creator.errorCodes"
                [ngClass]="
                  creator.errorCodes.length > 0
                    ? 'pi pi-exclamation-circle text-warning'
                    : 'pi pi-check-circle text-success'
                "
              ></i>
              <span class="ml-2">{{ i + 1 }}</span>
            </div>
          </td>
          <td>{{ creator.name || 'No Entry' }}</td>
          <td>
            <div class="slc-flex-c-c">
              {{ creator.errorCodes?.length || 0 }}
            </div>
          </td>
          <td>
            <div class="text-center">
              <button
                class="action-btn"
                (click)="deleteCreator(i)"
                *ngIf="data?.invalidCreators && data.invalidCreators.length > 1"
              >
                <i class="pi pi-trash"></i>
              </button>
              <button
                class="slc-btn slc-btn-icon"
                (click)="fixCreator(creator, i)"
              >
                Fix
                <i class="pi pi-chevron-right ml-1"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <button
      type="submit"
      class="slc-btn slc-btn-primary w-100 mt-4"
      [ngClass]="{
        'slc-btn-loading': isLoading
      }"
      [disabled]="isLoading || needFixes()"
      (click)="submitAll()"
    >
      <span>Fix & Send Invite</span>
    </button>
  </main>
</p-dialog>

<!-- Map Dialog -->
<slice-modal-xls-creator-map
  *ngIf="isFixDialogVisible"
  [isVisible]="isFixDialogVisible"
  [indexCreator]="selectedIndex"
  [invalidCreator]="selectedCreator"
  (save)="updateCreator($event)"
></slice-modal-xls-creator-map>
