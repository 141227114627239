export enum CampaignStatusType {
  newCampaign = 'newCampaign',
  pendingPublication = 'pendingPublication',
  editRequested = 'editRequested',
  inProgress = 'inProgress',
  contentInReview = 'contentInReview',
}
export enum CampaignModelStatus {
  IG_SCORED = 'IG_SCORED',
  YT_SCORED = 'YT_SCORED',
  SCORED = 'SCORED',
  LOOKING_FOR_CREATORS = 'LOOKING_FOR_CREATORS',
  BRAND_APPROVAL_PENDING = 'BRAND_APPROVAL_PENDING',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAID = 'PAID',
  ARCHIVED = 'ARCHIVED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  PITCH_CREATED = 'PITCH_CREATED',
  REPORT_CREATED = 'REPORT_CREATED',
  REPORT_ACTIVE = 'ACTIVE',
}

export enum ContractStatusList {
  WAITING_FOR_CREATOR = 'WAITING_FOR_CREATOR',
  WAITING_FOR_OTHER = 'WAITING_FOR_OTHER',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
}

export enum ErrorCodeBulkCreatorCampaign {
  INVALID_EMAIL = 'INVALID_EMAIL',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  ALL_ZERO_POST_TYPE_QUANTITY = 'ALL_ZERO_POST_TYPE_QUANTITY',
  INVALID_TT_VIDEO_QTY = 'INVALID_TT_VIDEO_QTY',
  INVALID_IG_REELS_QTY = 'INVALID_IG_REELS_QTY',
  INVALID_IG_STORY_QTY = 'INVALID_IG_STORY_QTY',
  INVALID_IG_POST_QTY = 'INVALID_IG_POST_QTY',
  INVALID_IG_CAROUSEL_QTY = 'INVALID_IG_CAROUSEL_QTY',
  INVALID_YT_HOST_READ_QTY = 'INVALID_YT_HOST_READ_QTY',
  INVALID_YT_PRODUCT_PLACEMENT_QTY = 'INVALID_YT_PRODUCT_PLACEMENT_QTY',
  INVALID_YT_PRODUCT_REVIEW_QTY = 'INVALID_YT_PRODUCT_REVIEW_QTY',
  INVALID_TT_VIDEO_PRICE = 'INVALID_TT_VIDEO_PRICE',
  INVALID_IG_REELS_PRICE = 'INVALID_IG_REELS_PRICE',
  INVALID_IG_STORY_PRICE = 'INVALID_IG_STORY_PRICE',
  INVALID_IG_POST_PRICE = 'INVALID_IG_POST_PRICE',
  INVALID_IG_CAROUSEL_PRICE = 'INVALID_IG_CAROUSEL_PRICE',
  INVALID_YT_HOST_READ_PRICE = 'INVALID_YT_HOST_READ_PRICE',
  INVALID_YT_PRODUCT_PLACEMENT_PRICE = 'INVALID_YT_PRODUCT_PLACEMENT_PRICE',
  INVALID_YT_PRODUCT_REVIEW_PRICE = 'INVALID_YT_PRODUCT_REVIEW_PRICE',
  INVALID_TT_USERNAME = 'INVALID_TT_USERNAME',
  INVALID_TT_FOLLOWERS = 'INVALID_TT_FOLLOWERS',
  INVALID_IG_USERNAME = 'INVALID_IG_USERNAME',
  INVALID_IG_FOLLOWERS = 'INVALID_IG_FOLLOWERS',
  INVALID_YT_USERNAME = 'INVALID_YT_USERNAME',
  INVALID_YT_FOLLOWERS = 'INVALID_YT_FOLLOWER',
}

export enum linkRedirect {
  instagram = 'https://www.instagram.com/',
  youtube = 'https://www.youtube.com/',
  tiktok = 'https://www.tiktok.com/@',
}
