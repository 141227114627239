import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'slc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit, OnDestroy {
  @Input() set markupHTML(val: string | null | undefined) {
    this.html = val;
  }
  @Input() markupHTML$?: BehaviorSubject<string | null | undefined>;
  @Input() tooltipKey: string | undefined;
  @Input() okBtnTitle: string | undefined;
  @Input() tooltipModal?: boolean;
  @Input() tooltipCssClass?: string | undefined;

  private destroy$ = new Subject<void>();
  public html: string | undefined | null;
  public isTouchScreen: boolean;
  public isModalVisible: boolean;

  ngOnInit(): void {
    if (this.markupHTML$) {
      this.markupHTML$
        .asObservable()
        .pipe(takeUntil(this.destroy$))
        .subscribe(r => {
          this.html = r;
        });
    }
    this.isTouchScreen = this.isTouchScreen =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (navigator as any).msMaxTouchPoints > 0;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleModal(event: MouseEvent): void {
    event.stopPropagation();
    this.isModalVisible = !this.isModalVisible;
  }
}
