export enum AgencyInvitationErrorStatus {
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_NAME = 'INVALID_NAME',
  INVALID_EARNINGS = 'INVALID_EARNINGS',
  INVALID_PHONE_NUMBER_FORMAT = 'INVALID_PHONENUMBER_FORMAT',
  INVALID_ACCOUNT_TYPE = 'INVALID_ACCOUNT_TYPE',
  INVALID_NPWP_FORMAT = 'INVALID_NPWP_FORMAT',
  INVALID_ID_CARD_FORMAT = 'INVALID_ID_CARD_FORMAT',
  INVALID_PAYMENT_METHOD = 'INVALID_PAYMENT_METHOD',
  INVALID_BANK_CODE = 'INVALID_BANK_CODE',
  INVALID_BANK_ACCOUNT_FORMAT = 'INVALID_BANK_ACCOUNT_FORMAT',
  INVALID_BANK_ACCOUNT_NUMBER = 'INVALID_BANK_ACCOUNT_NUMBER',
  BANK_ACCOUNT_NOT_FOUND = 'BANK_ACCOUNT_NOT_FOUND',
}
