import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { AgencyInvitationsHttpService } from '@slice-services/agency-invitation/agency-invitations-http.service';
import { WalletBalanceHttpService } from '@slice-services/wallet-balance-http.service';
import { MessageService } from 'primeng/api';

import { PaymentMethod } from '@slice-interfaces/agency-client-roster/agency-client-roster-item';
import {
  InvalidBulkCreatorData,
  InviteCreatorFormParams,
} from '@slice-interfaces/agency-invitation/agency-invite-payload';
import { BankList } from '@slice-interfaces/bulk-payment/campaign';
import { Select } from '@slice-interfaces/select/select';
import { CreatorKYCRole } from '@slice-enums/user-types.enum';

@Component({
  selector: 'slice-modal-xls-creator-map',
  templateUrl: './modal-xls-creator-map.component.html',
  styleUrls: ['./modal-xls-creator-map.component.scss'],
})
export class ModalXlsCreatorMapComponent implements OnChanges {
  @Input() isVisible = false;
  @Input() indexCreator?: number;
  @Input() invalidCreator?: InvalidBulkCreatorData;
  @Output() save = new EventEmitter<InvalidBulkCreatorData>();

  isLoading = false;
  isLoadingBankList = false;
  isLoadingBankCheck = false;
  isErrorFetchName = false;

  creatorForm: FormGroup;
  kycTypes: Select[];
  paymentMethods: Select[];
  bankList: Array<BankList> = [];
  PaymentMethod = PaymentMethod;
  CreatorKYCRole = CreatorKYCRole;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private walletHttpS: WalletBalanceHttpService,
    private agencyInvitationsHttpS: AgencyInvitationsHttpService,
  ) {}

  ngOnChanges(): void {
    this.initForm();
    this.initSelects();
    this.loadBankList();
    if (this.invalidCreator?.paymentMethod) {
      this.paymentSelected({
        value:
          this.invalidCreator?.paymentMethod === PaymentMethod.SLICE_WALLET
            ? PaymentMethod.SLICE_WALLET
            : PaymentMethod.BANK_TRANSFER,
        label: '',
      });
    } else {
      this.paymentSelected({} as any);
    }

    setTimeout(() => {
      this.creatorForm.markAsDirty();
    }, 10);
  }

  initForm(): void {
    this.creatorForm = this.fb.group({
      email: this.fb.control(this.invalidCreator?.email, {
        validators: [Validators.required, Validators.email],
      }),
      name: this.fb.control(this.invalidCreator?.name || '', {
        validators: !this.invalidCreator?.userId ? [Validators.required] : null,
      }),
      waPhoneNumber: this.fb.control(
        this.invalidCreator?.waPhoneNumber || null,
        {
          validators: [Validators.required],
        },
      ),
      paymentMethod: this.fb.control(
        this.invalidCreator?.paymentMethod || null,
      ),
      // BANK_TRANSFER only form
      bankCode: this.fb.control(this.invalidCreator?.bankCode || null),
      bankAccountNumber: this.fb.control(
        this.invalidCreator?.bankAccountNumber || null,
      ),
      bankAccountName: this.fb.control({
        disabled: true,
        value: this.invalidCreator?.bankAccountName || null,
      }),
      // BANK_TRANSFER only form
      address: this.fb.control(this.invalidCreator?.address || null),
      kycType: this.fb.control(this.invalidCreator?.kycType || null),
      idCardNumber: this.fb.control(this.invalidCreator?.idCardNumber || null),
      companyName: this.fb.control(this.invalidCreator?.companyName || null),
      npwpNumber: this.fb.control(this.invalidCreator?.npwpNumber || null),
      earnings: this.fb.control(this.invalidCreator?.earnings || null), // INDIVIDUAL only form
      avatar: this.fb.control(this.invalidCreator?.avatar || null),
      userId: this.fb.control(this.invalidCreator?.userId || null),
    } as Record<keyof InviteCreatorFormParams, FormControl>);
  }

  initSelects(): void {
    this.kycTypes = [
      {
        label: 'Personal',
        value: CreatorKYCRole.INDIVIDUAL,
      },
      {
        label: 'Company',
        value: CreatorKYCRole.COMPANY,
      },
    ];
    this.paymentMethods = [
      { label: 'Bank Transfer', value: PaymentMethod.BANK_TRANSFER },
      { label: 'Slice Wallet', value: PaymentMethod.SLICE_WALLET },
    ];
  }

  loadBankList(): void {
    this.isLoadingBankList = true;
    this.walletHttpS
      .fetchBankAccountv2()
      // .pipe(
      //   tap(data => {
      //     const sliceWallet = {
      //       name: 'Slice Digital Wallet',
      //       code: 'slice',
      //       active: true,
      //       govBankCode: 'slice',
      //       inquirable: true,
      //     };
      //     this.bankList = [sliceWallet, ...data];
      //   }),
      // )
      .subscribe(
        data => {
          this.isLoadingBankList = false;
          this.bankList = data;
        },
        () => {
          this.isLoadingBankList = false;
          this.messageService.add({
            severity: 'error',
            detail: 'Failed to load bank list. Please try again later.',
          });
        },
      );
  }

  paymentSelected(v: Select) {
    if (v.value === PaymentMethod.BANK_TRANSFER) {
      this.creatorForm.get('bankCode')?.setValidators([Validators.required]);
      this.creatorForm
        .get('bankAccountNumber')
        ?.setValidators([Validators.required]);
      this.creatorForm
        .get('bankAccountName')
        ?.setValidators([Validators.required]);
      this.creatorForm
        .get('creatorConsent')
        ?.setValidators([Validators.requiredTrue]);
    } else {
      this.creatorForm.get('bankCode')?.clearValidators();
      this.creatorForm.get('bankAccountNumber')?.clearValidators();
      this.creatorForm.get('bankAccountName')?.clearValidators();
      this.creatorForm.get('creatorConsent')?.clearValidators();
    }
    this.creatorForm.get('bankCode')?.updateValueAndValidity();
    this.creatorForm.get('bankAccountNumber')?.updateValueAndValidity();
    this.creatorForm.get('bankAccountName')?.updateValueAndValidity();
    this.creatorForm.get('creatorConsent')?.updateValueAndValidity();
  }

  get phoneFormCtrl(): FormControl {
    return this.creatorForm.get('waPhoneNumber') as FormControl;
  }
  get bankAccountNumberCtrl(): AbstractControl {
    return this.creatorForm.get('bankAccountNumber') as AbstractControl;
  }
  get bankAccountNameCtrl(): AbstractControl {
    return this.creatorForm.get('bankAccountName') as AbstractControl;
  }

  checkBank() {
    if (this.creatorForm.get('bankCode')?.value) {
      this.isLoadingBankCheck = true;
      this.isErrorFetchName = false;
      this.walletHttpS
        .fetchAccountInquiry({
          bankCode: this.creatorForm.get('bankCode')?.value,
          accountNumber: this.creatorForm.get('bankAccountNumber')?.value,
        })
        .subscribe(
          r => {
            this.isLoadingBankCheck = false;
            this.creatorForm.get('bankAccountName')?.patchValue(r?.accountName);
          },
          error => {
            this.isLoadingBankCheck = false;
            this.isErrorFetchName = true;
          },
        );
    }
  }

  onSubmit(): void {
    if (this.creatorForm.valid && this.phoneFormCtrl.value) {
      const formData = { ...this.creatorForm.getRawValue() };
      formData.index = this.indexCreator;
      formData.waPhoneNumber = this.phoneFormCtrl?.value?.number;
      this.save.emit(formData);
    }
  }

  onCancel(): void {
    this.save.emit(undefined);
  }
}
