<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="closed.emit()"
>
  <p-header>
    <span class="grey-title">
      {{ 'agency.client-roster.add-new-client.main-title' | transloco }}
    </span>
  </p-header>

  <main>
    <div
      class="warning-msg slc-flex-fs-c slc-mb-24"
      *ngIf="currAmount + 1 >= limitAmount"
    >
      <slc-svg-icon-info-circle></slc-svg-icon-info-circle>

      <p class="slc-ml-8">
        <span *ngIf="currAmount + 1 === limitAmount">
          {{ 'agency.client-roster.upgrage-plan.limit-1' | transloco }},&nbsp;
        </span>

        <span *ngIf="currAmount === limitAmount">
          {{ 'agency.client-roster.upgrage-plan.limit-0' | transloco }},&nbsp;
        </span>

        <button
          type="button"
          class="slc-text-color-primary slc-fw-700"
          (click)="showUpgrade()"
        >
          {{ 'agency.client-roster.upgrage-plan.upgrade-btn' | transloco }}
        </button>
      </p>
    </div>

    <div class="form-title">
      {{ 'agency.client-roster.add-new-client.form-title' | transloco }}
    </div>

    <slice-invite-creator-email
      [disabled]="isLimitReached"
      [selectedUser]="formCreator"
      (clientsAdded)="clientsAdded($event)"
    ></slice-invite-creator-email>

    <div class="form-title slc-mt-32">
      {{ 'agency.client-roster.add-new-client.list-title' | transloco }}
    </div>

    <slice-invite-creator-list
      [creatorList]="formCreator"
      (clientsEdited)="clientsEdited($event)"
      (clientsRemoved)="clientsRemoved($event)"
    ></slice-invite-creator-list>

    <div class="w-100 slc-flex-c-c">
      <button
        title="{{ 'buttons.send-invitation' | transloco }}"
        class="slc-btn slc-btn-primary slc-mt-16 w-100"
        (click)="submit()"
        [disabled]="isEmptyList"
        [ngClass]="{
          'slc-btn-loading': loading
        }"
        type="button"
      >
        <span>{{ 'buttons.send-invitation' | transloco }}</span>
      </button>
    </div>
    <div class="text-center mt-3">
      <a (click)="openModalCsv()" class="import-csv">{{
        'buttons.import-xls-creator' | transloco
      }}</a>
    </div>
  </main>
</p-dialog>
<ng-container *ngIf="isVisibleModalCsv">
  <slice-modal-csv-creator (closed)="closeCsv()"></slice-modal-csv-creator>
</ng-container>
<ng-container *ngIf="formCreatorModal">
  <slice-invite-creator-detail
    [dialogData]="formCreatorModal"
    (closed)="closeModalDetail($event)"
  ></slice-invite-creator-detail>
</ng-container>
