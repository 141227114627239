import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

import { createHttpParams } from '@slc-libs/helpers';

import { environment } from '@slice-env/environment';

import { AdminCreatorsFilters } from '@slice-interfaces/admin/creators/filters';
import { AdminCreatorsResponse } from '@slice-interfaces/admin/creators/get-list';
import { AdminHomeCampaigsFilters } from '@slice-interfaces/admin/home-campaigns/filters';
import { AdminHomeCampaigsResponse } from '@slice-interfaces/admin/home-campaigns/get-list';
import {
  CampaignDetailsModel,
  CampaignDetailsPublic,
} from '@slice-interfaces/brand-campaign-details';
import { CampaignCreatePayload } from '@slice-interfaces/campaign-create/create-campaign';
import { CampaignManagerFilters } from '@slice-interfaces/campaign-manager/filters';
import { CampaignManagerResponse } from '@slice-interfaces/campaign-manager/get-list';
import {
  CampaignDashboard,
  CampaignsParams,
} from '@slice-interfaces/campaign-status/filters';
import { CampaignsResponse } from '@slice-interfaces/campaign-status/get-list';
import { CreatorMediaKitResponseModel } from '@slice-interfaces/media-kit/creator-mediakit';
import { CampaignModelStatus } from '@slice-enums/campaign-status';

import { getRandomHttpResponseDelay } from '../_mocks/_helpers/http-response-delay';
import { getAdminCreatorsMocks } from '../_mocks/admin/creators';
import { getAdminHomeCampaignsMocks } from '../_mocks/admin/home-campaigns';
import {
  getBrandCampaignDetailsMock,
  getCampaignDetailsPublicMock,
} from '../_mocks/brand/brand-campaign-details';
import { getCampaignsMocks } from '../_mocks/brand/brand-campaign-list';
import { getCampaignManagerMocks } from '../_mocks/home-manager-campaigns';

@Injectable({
  providedIn: 'root',
})
export class CampaignHttpService {
  constructor(private http: HttpClient) {}
  getAdminCampaigns(
    params: AdminHomeCampaigsFilters,
  ): Observable<AdminHomeCampaigsResponse> {
    return of(getAdminHomeCampaignsMocks(params)).pipe(delay(500));
  }

  getAdminCreators(
    params: AdminCreatorsFilters,
  ): Observable<AdminCreatorsResponse> {
    return of(getAdminCreatorsMocks(params)).pipe(delay(500));
  }
  createCampaign(payload: CampaignCreatePayload): Observable<{ id: string }> {
    return this.http.post<{ id: string }>('/api/v1/campaigns', payload);
  }

  getCampaigns(
    params: CampaignsParams,
    useMocks?: boolean,
  ): Observable<CampaignsResponse | null> {
    const mock = of(getCampaignsMocks(params)).pipe(delay(700));
    const req = this.http
      .get<CampaignsResponse>('/api/v1/campaigns', {
        params: createHttpParams(params as any),
      })
      .pipe(catchError(() => of(null)));

    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mock : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mock : req;
    }
  }

  getManagerCampaigns(
    params: CampaignManagerFilters,
  ): Observable<CampaignManagerResponse | null> {
    const req = this.http
      .get<CampaignManagerResponse>(
        '/api/v1/api/v1/todo-get-campaigns-manager',
        {
          params: createHttpParams(params as any),
        },
      )
      .pipe(catchError(() => of(null)));
    const mock = of(getCampaignManagerMocks(params)).pipe(
      delay(getRandomHttpResponseDelay()),
    );

    if (environment.production) {
      if (environment.isDemo) {
        return mock;
      } else {
        return req;
      }
    } else {
      return req;
    }
  }

  getCampaignById(p: {
    id: string;
    accessCode?: string;
    useMock?: boolean;
  }): Observable<CampaignDetailsModel> {
    const mock = of(getBrandCampaignDetailsMock()).pipe(delay(500));
    const req = this.http.get<CampaignDetailsModel>(
      `/api/v1/campaigns/${p.id}`,
      {
        params: createHttpParams({ code: p.accessCode }),
      },
    );
    if (environment.production) {
      if (environment.isDemo) {
        return p.useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return p.useMock ? mock : req;
    }
  }

  getPublicCampaignById(
    id: string,
    secureCode: string,
    useMock?: boolean,
  ): Observable<CampaignDetailsPublic> {
    const mock = of(getCampaignDetailsPublicMock()).pipe(delay(500));
    const req = this.http.get<CampaignDetailsPublic>(
      `/api/public/v1/campaigns/${id}`,
      {
        params: createHttpParams({ code: secureCode }),
      },
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }

  getCampaignStatus(
    id: string,
    useMock?: boolean,
  ): Observable<{
    status: CampaignModelStatus;
  }> {
    const statuses = Object.values(CampaignModelStatus);
    const status = statuses[Math.floor(Math.random() * statuses.length)];
    const mock = of({ status: status }).pipe(delay(100));
    const req = this.http.get<{
      status: CampaignModelStatus;
    }>(`/api/v1/campaigns/${id}/status`);
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }

  pay(id: string, useMocks?: boolean): Observable<void> {
    const req = this.http.post<void>(
      `/api/v1/campaigns/${id}/pay`,
      {},
      {
        params: createHttpParams({ id }),
      },
    );
    const mock = of(undefined as any).pipe(delay(500));
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mock : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mock : req;
    }
  }

  patchCampaignPublicComment(
    id: string,
    code: string,
    comment: string,
    useMocks?: boolean,
  ): Observable<void> {
    const req = this.http.post<void>(
      `/api/v1/campaigns/${id}/pay`,
      {},
      {
        params: createHttpParams({ id }),
      },
    );
    const mock = of(undefined as any).pipe(delay(500));
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mock : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mock : req;
    }
  }

  deletePitch(campaignId: string, useMocks?: boolean): Observable<void> {
    const req = this.http.delete<void>(`/api/v1/campaigns/${campaignId}`, {});
    const mock = of(undefined as any).pipe(delay(500));
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mock : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mock : req;
    }
  }

  getMediaKitById(p: {
    id: string;
    accessCode?: string;
    useMock?: boolean;
  }): Observable<CreatorMediaKitResponseModel> {
    const mock = of(getBrandCampaignDetailsMock()).pipe(delay(500)) as any;
    const req = this.http.get<CreatorMediaKitResponseModel>(
      p.accessCode
        ? `/api/public/v1/creators/${p.id}/media-kit`
        : `/api/v1/creators/${p.id}/media-kit`,
      {
        params: createHttpParams({ securityCode: p.accessCode }),
      },
    );
    if (environment.production) {
      if (environment.isDemo) {
        return p.useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return p.useMock ? mock : req;
    }
  }

  campaignArchiving(
    archive: string,
    campaignIds: Array<string>,
    useMocks?: boolean,
  ): Observable<void> {
    const req = this.http.put<void>(`/api/v1/campaigns/${archive}`, {
      campaignIds,
    });
    const mock = of(undefined as any).pipe(delay(500));
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mock : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mock : req;
    }
  }

  getDashboardCampaign(agencyId: string): Observable<CampaignDashboard> {
    return this.http.get<CampaignDashboard>(
      `/api/v1/agencies/${agencyId}/monthly-stats`,
    );
  }
}
