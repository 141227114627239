<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="closed.emit()"
>
  <p-header>
    <span class="grey-title text-left"> Creator Detail </span>
  </p-header>

  <main>
    <form [formGroup]="creatorForm" (ngSubmit)="submitForm()">
      <section class="w-100">
        <slc-input-wrapper title="Email">
          <input
            type="email"
            class="slc-input"
            placeholder="Email Address"
            formControlName="email"
          />
        </slc-input-wrapper>

        <slc-input-wrapper title="Full Name" [isRequired]="!dialogData?.userId">
          <input
            type="text"
            class="slc-input"
            placeholder="Enter full name"
            formControlName="name"
          />
        </slc-input-wrapper>

        <slc-input-wrapper
          title="WhatsApp Phone Number (optional)"
          [hidden]="dialogData.userId"
        >
          <slc-phone [formCtrl]="phoneFormCtrl"></slc-phone>
        </slc-input-wrapper>

        <slc-input-wrapper title="Preferred Payment Method (optional)">
          <ng-select
            class="slice-ng-select"
            [items]="paymentMethods"
            bindLabel="label"
            bindValue="value"
            placeholder="Select Preferred Payment Method"
            formControlName="paymentMethod"
            [clearable]="true"
            (change)="paymentSelected($event)"
          ></ng-select>
        </slc-input-wrapper>

        <ng-container
          *ngIf="
            creatorForm.get('paymentMethod')?.value ===
            PaymentMethod.BANK_TRANSFER
          "
        >
          <slc-input-wrapper title="Bank Selection" [isRequired]="true">
            <ng-select
              class="slice-ng-select channel-select"
              [items]="bankList"
              bindLabel="name"
              bindValue="govBankCode"
              placeholder="Select Bank"
              formControlName="bankCode"
              [searchable]="true"
              (change)="
                bankAccountNumberCtrl.patchValue(null);
                bankAccountNameCtrl.patchValue(null)
              "
            >
            </ng-select>
          </slc-input-wrapper>

          <slc-input-wrapper title="Account Number" [isRequired]="true">
            <div class="d-flex align-items-center">
              <input
                type="text"
                class="slc-input"
                placeholder="Enter Bank Account Number"
                formControlName="bankAccountNumber"
                (keyup.enter)="checkBank()"
                (blur)="checkBank()"
              />
              <button
                type="button"
                class="button-amount"
                [disabled]="
                  !creatorForm.get('bankAccountNumber')?.value ||
                  !creatorForm.get('bankCode')?.value
                "
                (click)="checkBank()"
              >
                <span *ngIf="!isLoadingBankCheck">Check</span>
                <slice-loader *ngIf="isLoadingBankCheck"></slice-loader>
              </button>
            </div>
            <p *ngIf="isErrorFetchName" class="text-danger mt-1">
              Invalid Bank Account Number
            </p>
          </slc-input-wrapper>

          <slc-input-wrapper *ngIf="creatorForm.get('bankAccountName')?.value">
            <input
              type="text"
              class="slc-input input-amount"
              [value]="creatorForm.get('bankAccountName')?.value"
              style="
                background-color: rgba(77, 187, 123, 0.1) !important;
                border-color: rgba(77, 187, 123, 0.1);
              "
              disabled
            />
            <button class="button-amount">
              <slc-svg-icon-check-circle></slc-svg-icon-check-circle>
              <!-- <i class="pi pi-check text-success"></i> -->
            </button>
          </slc-input-wrapper>
        </ng-container>

        <slc-input-wrapper
          title="Account Type (optional)"
          [hidden]="dialogData.userId"
        >
          <ng-select
            class="slice-ng-select"
            [items]="accountTypes"
            bindLabel="label"
            bindValue="value"
            placeholder="Select Account Type"
            formControlName="kycType"
            [clearable]="true"
          ></ng-select>
        </slc-input-wrapper>

        <ng-container *ngIf="creatorForm.get('kycType')?.value as kycType">
          <slc-input-wrapper title="ID Card (optional)">
            <input
              type="text"
              class="slc-input"
              placeholder="Enter KTP Number"
              formControlName="idCardNumber"
            />
          </slc-input-wrapper>

          <slc-input-wrapper title="NPWP Number (optional)">
            <input
              type="text"
              class="slc-input"
              placeholder="Enter NPWP Number"
              formControlName="npwpNumber"
            />
          </slc-input-wrapper>

          <slc-input-wrapper
            title="Company Name (optional)"
            *ngIf="kycType === CreatorKYCRole.COMPANY"
          >
            <input
              type="text"
              class="slc-input"
              placeholder="Enter Company Name"
              formControlName="companyName"
            />
          </slc-input-wrapper>

          <slc-input-wrapper
            title="Annual Earnings (optional)"
            *ngIf="kycType === CreatorKYCRole.INDIVIDUAL"
          >
            <p-inputNumber
              locale="en-US"
              name="currentEarnings"
              placeholder="Enter Amount"
              formControlName="earnings"
            ></p-inputNumber>
          </slc-input-wrapper>
        </ng-container>

        <slc-input-wrapper
          title="Address (optional)"
          [hidden]="dialogData.userId"
        >
          <textarea
            type="text"
            class="slc-input"
            placeholder="Enter address for product shipping"
            style="padding-left: 10px"
            rows="3"
            formControlName="address"
          ></textarea>
        </slc-input-wrapper>

        <slc-input-wrapper
          *ngIf="
            creatorForm.get('paymentMethod')?.value ===
            PaymentMethod.BANK_TRANSFER
          "
        >
          <p-checkbox
            name="checkbox"
            styleClass="slc-checkbox-square"
            formControlName="creatorConsent"
            [binary]="true"
            [label]="
              'This creator has provided consent to save bank account details for payment purposes'
            "
          ></p-checkbox>
        </slc-input-wrapper>
      </section>

      <button
        type="submit"
        class="slc-btn slc-btn-primary w-100 mt-4"
        [disabled]="creatorForm.invalid"
      >
        <span>Save</span>
      </button>
    </form>
  </main>
</p-dialog>
