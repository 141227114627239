import { Component, Input, TemplateRef } from '@angular/core';

import { SortMeta } from 'primeng/api';
import { Table } from 'primeng/table';

import { TableColumn, TableColumnAlignment } from '@slc-libs/tables';
import { TABLES_CELL_TYPE, TABLES_MOBILE_BREAKPOINT } from '@slc-libs/tables';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slc-table-body-row]',
  templateUrl: './table-body-row.component.html',
  styleUrls: ['./table-body-row.component.scss'],
})
export class TableBodyRowComponent {
  @Input() isExpanRow: boolean;
  @Input() pTable!: Table;
  @Input() multiSortMeta?: Array<SortMeta> | undefined;
  @Input() windowWidth!: number;
  @Input() columns!: Array<TableColumn>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() item!: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() index!: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() expandColumnTmpl?: TemplateRef<any>;

  public alignments = {
    [TableColumnAlignment.center]: 'slc-text-center-i',
    [TableColumnAlignment.left]: 'slc-text-left-i',
    [TableColumnAlignment.right]: 'slc-text-right-i',
  };
  public readonly NUMBERS_DECIMAL_VIEW = '1.0-2';
  public readonly DATE_FORMAT = 'd MMM yyyy';
  public readonly TABLES_CELL_TYPE = TABLES_CELL_TYPE;
  public readonly TABLES_MOBILE_BREAKPOINT = TABLES_MOBILE_BREAKPOINT;

  isNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
}
