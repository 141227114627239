import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { BulkPaymentCampaignHttpService } from '@slice-services/bulk-payment-campaign-http.service';
import { ClientsRosterHttpService } from '@slice-services/clients-roster/clients-roster-http.service';
import { MixPanelService } from '@slice-services/mix-panel.service';
import { ProfileHttpService } from '@slice-services/profiles-http.service';
import { WalletBalanceHttpService } from '@slice-services/wallet-balance-http.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Channels, ENVIRONMENTS, PostTypes } from '@slc-libs/enums';
import { BreakpointsService } from '@slc-libs/services';

import { environment } from '@slice-env/environment';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import {
  AgencyClientPaymentInfo,
  AgencyClientsRosterDetail,
  AgencyClientsRosterItem,
  PaymentMethod,
} from '@slice-interfaces/agency-client-roster/agency-client-roster-item';
import { KycInterface } from '@slice-interfaces/profile/creator-profile';
import { MIX_PANEL_EVENTS } from '@slice-enums/mix-panel-events.enum';
import { CreatorKYCRole } from '@slice-enums/user-types.enum';

@Component({
  selector: 'slice-creator-detail-crm',
  templateUrl: './creator-detail-crm.component.html',
  styleUrls: ['./creator-detail-crm.component.scss'],
})
export class CreatorDetailCrmComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() invitationId: string;
  @Input() userId: string;
  @Input() dataUser?: AgencyClientsRosterItem;
  @Input() userChannels: Array<Channels>;
  @Output() closed = new EventEmitter<boolean>();
  @Output() modalEarning = new EventEmitter<boolean>();
  @ViewChild('ngForm') ngForm: NgForm;

  public activeTabIndex = [0, 1, 4];
  public CreatorKYCRole = CreatorKYCRole;
  public isVisible = true;
  public loading = false;
  public creatorDetail: AgencyClientsRosterDetail =
    {} as AgencyClientsRosterDetail;
  public creatorKyc: KycInterface;
  public bulkPayment: number;
  public isKyc: boolean;
  public oldNotes: string;
  public notes: string;
  public igRateCard: Array<{
    postType: PostTypes;
    price: number;
  }> = [];
  public tiktokRateCard: Array<{
    postType: PostTypes;
    price: number;
  }> = [];
  public ytRateCard: Array<{
    postType: PostTypes;
    price: number;
  }> = [];
  public appearanceRateCard: Array<{
    postType: PostTypes;
    price: number;
  }> = [];

  constructor(
    public mS: MessageService,
    public tS: TranslocoService,
    public bS: BreakpointsService,
    private mpS: MixPanelService,
    public clientsRosterHttpS: ClientsRosterHttpService,
    public profileHttpS: ProfileHttpService,
    public walletBalanceHttpService: WalletBalanceHttpService,
    public bulkPaymentCampaignHttpService: BulkPaymentCampaignHttpService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.dataUser?.userStatus !== 'NOT_REGISTERED') {
      this.loading = true;
      this.clientsRosterHttpS
        .getAgencyRosterCRM(this.invitationId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          v => {
            this.loading = false;
            this.creatorDetail = v;
            this.creatorKyc = v.creatorKyc as KycInterface;
            // this.getKyc(v.creatorUserId);
            this.getBulkPayment(v.creatorUserId);
            this.getNoteForm();
            this.populateRateCard();
          },
          err => {
            this.loading = false;
            throwError(err);
          },
        );
    } else {
      this.loading = true;
      this.creatorDetail = {
        fullName: this.dataUser.creatorName,
        companyName: this.dataUser.companyName ?? '',
        mediaKitUrl: '',
        favorited: false,
        creatorNotes: '',
        creatorUserId: this.dataUser.userId,
        status: this.dataUser.status,
        pastPerformance: null,
        recentPartnership: null,
        avatar: '',
        categories: [],
        personalInfo: {
          email: this.dataUser.creatorEmail ?? '',
        },
        rateCard: [],
      };
      this.getBulkPayment(this.dataUser.userId);

      // this.walletBalanceHttpService
      //   .fetchLatestPayment(this.dataUser?.userId)
      //   .pipe(takeUntil(this.destroy$))
      //   .subscribe(
      //     v => {
      //       this.loading = false;
      //       this.creatorKycNonSlice = v;
      //     },
      //     err => {
      //       this.loading = false;
      //       throwError(err);
      //     },
      //   );
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // getKyc(id: string): void {
  //   this.loading = true;
  //   this.profileHttpS
  //     .getCreatorKyc(id)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(
  //       v => {
  //         this.loading = false;
  //         this.creatorKyc = v;
  //       },
  //       err => {
  //         this.loading = false;
  //         throwError(err);
  //       },
  //     );
  // }

  getBulkPayment(id: string): void {
    this.loading = true;
    const currentDate = new Date();
    const currentDateYear = currentDate.getFullYear() as number;
    this.bulkPaymentCampaignHttpService
      .getEarningHistory(id, {
        year: currentDateYear,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        v => {
          this.loading = false;
          this.bulkPayment = v?.totalEarnings;
          this.isKyc = v.alreadyKyc;
        },
        err => {
          this.loading = false;
          throwError(err);
        },
      );
  }

  getAge(date: any): number {
    if (date) {
      const birthDate = moment(date);
      const today = moment();

      return today.diff(birthDate, 'years');
    } else {
      return 0;
    }
  }

  addFavorite() {
    const isFavorite = !this.creatorDetail?.favorited;
    if (isFavorite) {
      this.mpS.track(MIX_PANEL_EVENTS.agency_favorite_creator, {
        $referrer: 'crm_dialog',
      });
    }
    this.clientsRosterHttpS
      .postAgencyRosterFavorites(this.creatorDetail?.agencyUserId as string, {
        favorited: isFavorite,
      })
      .subscribe(() => {
        this.mS.add({
          severity: 'success',
          life: 5 * 1000,
          detail: this.tS.translate('common.changes-saved'),
        });
        this.creatorDetail.favorited = isFavorite;
      });
  }

  getMediaKitUrl(): string {
    return environment.uiHost + this.creatorDetail.mediaKitUrl;
  }

  getRateCardUrl(): string {
    const originUrl =
      environment.envType === ENVIRONMENTS.prod
        ? 'https://slice.id'
        : window.location.origin;
    return originUrl + this.creatorDetail.mediaKitUrl;
  }

  goToMediaKit(): void {
    this.mpS.track(MIX_PANEL_EVENTS.agency_open_mediakit_publink);
    // console.log('goToMediaKit', environment.uiHost  + this.creatorDetail.mediaKitUrl);
    // window.open(environment.uiHost + this.creatorDetail.mediaKitUrl, '_blank');
    // window.open(
    //   environment.uiHost + '/p-mediakit/' + this.creatorId + '/7bb539a0-3bda-4833-81b7-39da07091958', '_blank'
    // );
  }

  getNoteForm(): void {
    this.oldNotes = this.creatorDetail?.creatorNotes;
    this.notes = this.creatorDetail?.creatorNotes;
  }

  populateRateCard(): void {
    if (this.creatorDetail.rateCard.length == 0) return;
    this.creatorDetail.rateCard.forEach(rate => {
      const ratePerChannel = {
        postType: rate.postType,
        price: rate.price,
      };
      if (rate.channel == Channels.INSTAGRAM) {
        this.igRateCard.push(ratePerChannel);
      } else if (rate.channel == Channels.TIKTOK) {
        this.tiktokRateCard.push(ratePerChannel);
      } else if (rate.channel == Channels.YOUTUBE) {
        this.ytRateCard.push(ratePerChannel);
      } else if (rate.channel == Channels.APPEARANCE) {
        this.appearanceRateCard.push(ratePerChannel);
      }
    });
  }

  updateLastPayment(e: {
    paymentInfo: AgencyClientPaymentInfo;
    bankName?: string;
  }) {
    if (this.dataUser && e.paymentInfo) {
      this.dataUser.lastPaymentMethod = e.paymentInfo.paymentMethod as any;
      if (this.dataUser.lastPaymentMethod === PaymentMethod.BANK_TRANSFER) {
        this.dataUser.bankAccountName = e.paymentInfo.bankAccountName as any;
        this.dataUser.bankAccountNumber = e.paymentInfo
          .bankAccountNumber as any;
        this.dataUser.bankDetail = {
          ...this.dataUser?.bankDetail,
          name: e.bankName as string,
        } as any;
      }
    } else {
      console.log('failed to update Creator Last Payment Data');
    }
  }

  onHide(): void {
    this.closed?.emit(true);
  }

  openEarning(): void {
    this.modalEarning?.emit(true);
  }

  onSubmit(): void {
    if (this.ngForm.valid) {
      this.loading = true;
      this.mpS.track(MIX_PANEL_EVENTS.agency_submit_creator_notes);
      // this.closed?.emit(undefined);
      this.clientsRosterHttpS
        .postAgencyRosterNotes(this.creatorDetail?.agencyUserId as string, {
          creatorNotes: this.notes,
        })
        .subscribe(
          () => {
            this.loading = false;
            this.mS.add({
              severity: 'success',
              life: 5 * 1000,
              detail: this.tS.translate('common.changes-saved'),
            });
            this.creatorDetail.creatorNotes = this.notes;
            this.getNoteForm();
          },
          err => {
            this.loading = false;
            throwError(err);
          },
        );
    }
  }
}
