import { NgModule } from '@angular/core';

import { FullCalendarModule } from '@fullcalendar/angular';
import { NgOtpInputModule } from 'ng-otp-input';
import { ClipboardModule } from 'ngx-clipboard';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { MenuModule } from 'primeng/menu';
import { PaginatorModule } from 'primeng/paginator';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';

import { AppAvatarModule } from '@slc-libs/avatar';
import { AppCheckboxModule } from '@slc-libs/checkbox';
import { LandingFooterModule } from '@slc-libs/footer';
import { InputWrapperModule } from '@slc-libs/input-wrapper';
import { InputWrapperSpaceBetweenModule } from '@slc-libs/input-wrapper-space-between';
import { PhoneModule } from '@slc-libs/phone';
import { AppPipesModule } from '@slc-libs/pipes';
import {
  SvgIconAgencyModule,
  SvgIconAnalyticsModule,
  SvgIconArchiveModule,
  SvgIconArrowInCircleModule,
  SvgIconArrowRightModule,
  SvgIconBagModule,
  SvgIconBankdModule,
  SvgIconBrandModule,
  SvgIconCalendar1Module,
  SvgIconCalendarModule,
  SvgIconCheckboxIconModule,
  SvgIconCheckboxPolicyModule,
  SvgIconCheckCircleModule,
  SvgIconCheckmarkModule,
  SvgIconChevronDoubleInCircleModule,
  SvgIconChevronModule,
  SvgIconCircleWavyCheckModule,
  SvgIconCityModule,
  SvgIconClockModule,
  SvgIconCloseCircleModule,
  SvgIconCloseModule,
  SvgIconCoinsModule,
  SvgIconDownloadFileModule,
  SvgIconDropboxModule,
  SvgIconEditModule,
  SvgIconExcoModule,
  SvgIconEye1Module,
  SvgIconFacebookModule,
  SvgIconFileDetailModule,
  SvgIconFunnelAwarenessModule,
  SvgIconFunnelConsiderationModule,
  SvgIconFunnelConversionModule,
  SvgIconGoogleDriveModule,
  SvgIconGoogleModule,
  SvgIconHamburgerModule,
  SvgIconHomeModule,
  SvgIconImageModule,
  SvgIconInfoCircleModule,
  SvgIconInfoSquareModule,
  SvgIconInstagramLightModule,
  SvgIconInstagramModule,
  SvgIconInvoicingModule,
  SvgIconIsKYCModule,
  SvgIconLinkedinModule,
  SvgIconLinkModule,
  SvgIconLinkMultiModule,
  SvgIconList1Module,
  SvgIconListModule,
  SvgIconLogoModule,
  SvgIconLogoOneColorModule,
  SvgIconMailModule,
  SvgIconMediaKitModule,
  SvgIconOffersModule,
  SvgIconPaymentModule,
  SvgIconPhoneModule,
  SvgIconPhotoModule,
  SvgIconPlaneModule,
  SvgIconPlantModule,
  SvgIconPlayModule,
  SvgIconPlusCircleModule,
  SvgIconPlusModule,
  SvgIconQuestionCircleModule,
  SvgIconReportModule,
  SvgIconRevenueModule,
  SvgIconRssModule,
  SvgIconSearchBoldModule,
  SvgIconSearchModule,
  SvgIconSpotifyModule,
  SvgIconStoreModule,
  SvgIconTelegramModule,
  SvgIconTiktokModule,
  SvgIconTiktokWhiteModule,
  SvgIconToolsModule,
  SvgIconTrashModule,
  SvgIconTwitterModule,
  SvgIconUnarchiveModule,
  SvgIconUsersModule,
  SvgIconWalletModule,
  SvgIconWhatsappModule,
  SvgIconYoutubeModule,
} from '@slc-libs/svg-icons';
import { SwitcherModule } from '@slc-libs/switcher';
import { AppTablesModule } from '@slc-libs/tables';
import { TabsModule } from '@slc-libs/tabs';
import { TagModule } from '@slc-libs/tag';
import { AppTooltipModule } from '@slc-libs/tooltip';
import { WidgetSimpleModule } from '@slc-libs/widget-simple';

import { CardDashboardComponent } from '@slice-shared/components/card-dashboard/card-dashboard.component';
import { CustomRateCreatorComponent } from '@slice-shared/components/custom-rate-creator/custom-rate-creator.component';
import { CustomSowCreatorComponent } from '@slice-shared/components/custom-sow-creator/custom-sow-creator.component';
import { InviteCreatorEmailComponent } from '@slice-shared/components/invite-creator/add-via-email/invite-creator-email.component';
import { InviteCreatorListComponent } from '@slice-shared/components/invite-creator/invite-list/invite-creator-list.component';
import { ModalXlsCreatorFixComponent } from '@slice-shared/components/invite-creator/modal-xls-creator-fix/modal-xls-creator-fix.component';
import { ModalXlsCreatorMapComponent } from '@slice-shared/components/invite-creator/modal-xls-creator-fix/modal-xls-creator-map/modal-xls-creator-map.component';
import { AgencyReportInfoLimitComponent } from '@slice-shared/components/report-info-limit/info-limit.component';
import { AgencyReportMetricsComponent } from '@slice-shared/components/report-metrics/metrics.component';
import { VideoPlayerModule } from '@slice-shared/components/video-player/video-player.module';
import { PopupPositionDirective } from '@slice-shared/directives/popup-position';
import { ScrollNearEndDirective } from '@slice-shared/directives/scroll-end';
import { TypedTemplateDirective } from '@slice-shared/directives/typed';
import { ValidateEqualDirective } from '@slice-shared/directives/validate-equal';
import { CoreModule } from '@slice-core/core.module';

import { AddCreatorComponent } from './components/add-creator/add-creator.component';
import { AddCreatorSowComponent } from './components/add-creator-sow/add-creator-sow.component';
import { AddItemComponent } from './components/add-item/add-item.component';
import { AssetContainerComponent } from './components/asset-container/asset-container.component';
import { BrandLogoTitleComponent } from './components/brand-logo-title/brand-logo-title.component';
import { BreadcrumbsWithLinksComponent } from './components/breadcrumbs-with-links/breadcrumbs-with-links.component';
import { BriefProposalStatusLabelComponent } from './components/brief-proposal-status-label/status.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarMobileDateComponent } from './components/calendar-mobile-date/calendar-mobile-date.component';
import { AdminCampaignStatusComponent } from './components/campaign-status-admin/campaign-status.component';
import { CampaignStatusLabelComponent } from './components/campaign-status-label/campaign-status-label.component';
import { BalanceDetailSharedComponent } from './components/card-balance/balance-detail/balance-detail.component';
import { BalanceDetailButtonComponent } from './components/card-balance/balance-detail-button/balance-detail-button.component';
import { SharedCardBalanceComponent } from './components/card-balance/card-balance.component';
import { MoveBalanceComponent } from './components/card-balance/move-balance/move-balance.component';
import { MoveBalanceModalComponent } from './components/card-balance/move-balance-modal/move-balance-modal.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ChangePinComponent } from './components/change-pin/change-pin.component';
import { ForgotButtonComponent } from './components/change-pin/forgot-button/forgot-button.component';
import { ForgotPinModalComponent } from './components/change-pin/forgot-pin-modal/forgot-pin-modal.component';
import { ForgotVerificationCodeComponent } from './components/change-pin/forgot-verification-code/forgot-verification-code.component';
import { InputNewPinComponent } from './components/change-pin/input-new-pin/input-new-pin.component';
import { ChannelProposalStatusComponent } from './components/channel-proposal-status/channel-proposal-status.component';
import { ChannelProposalStatusLabelComponent } from './components/channel-proposal-status-label/status.component';
import { ClientsSelectorComponent } from './components/clients-selector/clients-selector.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { CreatorDetailCrmComponent } from './components/creator-crm/creator-detail-crm.component';
import { CreatorDetailPaymentComponent } from './components/creator-crm/creator-detail-payment/creator-detail-payment.component';
import { CreatorPerformanceComponent } from './components/creator-crm/creator-performance/creator-performance.component';
import { DetailContentPopupComponent } from './components/detail-content-popup/detail-content-popup.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileUploadDefaultComponent } from './components/file-upload-default/file-upload-default.component';
import { GenerateLinkComponent } from './components/generate-link/generate-link.component';
import { HowApproveContentComponent } from './components/how-approve-content/how-approve-content.component';
import { ImgCropperComponent } from './components/img-cropper/img-cropper.component';
import { InfoComponent } from './components/info/info.component';
import { InviteCreatorFromOutsideComponent } from './components/invite-creator/add-from-outside/invite-creator-from-outside.component';
import { InviteCreatorModalComponent } from './components/invite-creator/invite-creator-modal.component';
import { InviteCreatorDetailComponent } from './components/invite-creator/invite-detail/invite-creator-detail.component';
import { ModalCsvCreatorComponent } from './components/invite-creator/modal-csv-creator/modal-csv-creator.component';
import { ModalCsvCreatorResponseComponent } from './components/invite-creator/modal-csv-creator-response/modal-csv-creator-response.component';
import { LandingFooterComponent } from './components/landing-footer/landing-footer.component';
import { LangComponent } from './components/lang/lang.component';
import { ContactLinkComponent } from './components/link/contact-link.component';
import { NgSelectMultiLabelComponent } from './components/ng-select-multi-label/ng-select-multi-label.component';
import { NumberViewComponent } from './components/number-view/number-view.component';
import { OtpComponent } from './components/otp/otp.component';
import { PayCreatorsHomeModalComponent } from './components/pay-creators-home-modal/pay-creators-home-modal.component';
import { PopupInfoComponent } from './components/popup-info/popup-info.component';
import { AgencyProfileAvatarComponent } from './components/profile-avatar/profile-avatar.component';
import { ProfilePublicComponent } from './components/profile-public/profile-public.component';
import { PublicFooterComponent } from './components/public-footer/public-footer.component';
import { PublicHeaderComponent } from './components/public-header/public-header.component';
import { RadioComponent } from './components/radio/radio.component';
import { RatesSocialComponent } from './components/rates-social/rates-social.component';
import { ReportSubmissionModalComponent } from './components/report-submission-modal/report-submission-modal.component';
import { SearchBrandComponent } from './components/search-brand/search-brand.component';
import { SearchCampaignComponent } from './components/search-campaign/search-campaign.component';
import { SearchCreatorsComponent } from './components/search-creators/search-creators.component';
import { ShowMoreComponent } from './components/show-more/show-more.component';
import { SocialLabelComponent } from './components/social-label/social-label.component';
import { SocialTokenExpiredModalComponent } from './components/social-token-expired-modal/social-token-expired-modal.component';
import { SourceEmptyPlaceholderComponent } from './components/source-empty-placeholder/source-empty-placeholder.component';
import { SubmitContentPopupAssetRequirementsComponent } from './components/submit-content-popup/asset-requirements/submit-content-popup-asset-requirements.component';
import { SubmitContentPopupDateTimeComponent } from './components/submit-content-popup/date-time/date-time.component';
import { SubmitContentPopupPreviewComponent } from './components/submit-content-popup/preview/preview.component';
import { SubmitContentPopupComponent } from './components/submit-content-popup/submit-content-popup.component';
import { SubmitWithPinComponent } from './components/submit-with-pin/submit-with-pin.component';
import { TableNoItemsFoundComponent } from './components/table-no-items-found/table-no-items-found.component';
import { TagCreateComponent } from './components/tag-create/tag-create.component';
import { TiktokConnectComponent } from './components/tiktok-connect/tiktok-connect.component';
import { ModalTaxComponent } from './components/total-earning/modal-tax/modal-tax.component';
import { TotalEarningSharedComponent } from './components/total-earning/total-earning.component';
import { SliceTranslationComponent } from './components/translation/translation.component';
import { UpgradeModalComponent } from './components/upgrade-modal/upgrade-modal.component';
import { WizardProgressComponent } from './components/wizard-progress/wizard-progress.component';
import { WizardProgressHorizontalComponent } from './components/wizard-progress-horizontal/wizard-progress-horizontal.component';
import { WizardStepFooterComponent } from './components/wizard-step-footer/wizard-step-footer.component';

const externalModules = [
  FullCalendarModule,
  ClipboardModule,
  ImageCropperModule,
  NgOtpInputModule,
];

const primeNgModules = [
  PaginatorModule,
  AutoCompleteModule,
  SkeletonModule,
  ChartModule,
  FileUploadModule,
  CarouselModule,
  CalendarModule,
  AccordionModule,
  TableModule,
  MenuModule,
  ChipModule,
  CardModule,
  DialogModule,
  CalendarModule,
  CheckboxModule,
  ButtonModule,
];

const appLibsModules = [
  LandingFooterModule,
  AppTooltipModule,
  InputWrapperModule,
  InputWrapperSpaceBetweenModule,
  AppTablesModule,
  AppAvatarModule,
  WidgetSimpleModule,
  TagModule,
  TabsModule,
  PhoneModule,
  AppCheckboxModule,
  AppPipesModule,
  VideoPlayerModule,
  SwitcherModule,
];

const icons = [
  SvgIconAgencyModule,
  SvgIconAnalyticsModule,
  SvgIconArchiveModule,
  SvgIconArrowInCircleModule,
  SvgIconArrowRightModule,
  SvgIconBagModule,
  SvgIconBrandModule,
  SvgIconBankdModule,
  SvgIconCalendar1Module,
  SvgIconCalendarModule,
  SvgIconCheckboxPolicyModule,
  SvgIconCheckboxIconModule,
  SvgIconCheckCircleModule,
  SvgIconCheckmarkModule,
  SvgIconChevronDoubleInCircleModule,
  SvgIconChevronModule,
  SvgIconCircleWavyCheckModule,
  SvgIconCityModule,
  SvgIconClockModule,
  SvgIconCloseCircleModule,
  SvgIconCloseModule,
  SvgIconCoinsModule,
  SvgIconDropboxModule,
  SvgIconExcoModule,
  SvgIconEye1Module,
  SvgIconFacebookModule,
  SvgIconFileDetailModule,
  SvgIconFunnelAwarenessModule,
  SvgIconFunnelConsiderationModule,
  SvgIconFunnelConversionModule,
  SvgIconGoogleDriveModule,
  SvgIconGoogleModule,
  SvgIconHamburgerModule,
  SvgIconHomeModule,
  SvgIconImageModule,
  SvgIconInfoCircleModule,
  SvgIconInfoSquareModule,
  SvgIconInstagramLightModule,
  SvgIconInstagramModule,
  SvgIconInvoicingModule,
  SvgIconLinkedinModule,
  SvgIconLinkModule,
  SvgIconLinkMultiModule,
  SvgIconList1Module,
  SvgIconListModule,
  SvgIconLogoModule,
  SvgIconLogoOneColorModule,
  SvgIconMailModule,
  SvgIconMediaKitModule,
  SvgIconOffersModule,
  SvgIconPaymentModule,
  SvgIconPhoneModule,
  SvgIconPhotoModule,
  SvgIconPlaneModule,
  SvgIconPlantModule,
  SvgIconPlayModule,
  SvgIconPlusCircleModule,
  SvgIconPlusModule,
  SvgIconQuestionCircleModule,
  SvgIconReportModule,
  SvgIconRevenueModule,
  SvgIconRssModule,
  SvgIconSearchBoldModule,
  SvgIconSearchModule,
  SvgIconSpotifyModule,
  SvgIconStoreModule,
  SvgIconTelegramModule,
  SvgIconTiktokModule,
  SvgIconTiktokWhiteModule,
  SvgIconTrashModule,
  SvgIconToolsModule,
  SvgIconTwitterModule,
  SvgIconUnarchiveModule,
  SvgIconUsersModule,
  SvgIconWalletModule,
  SvgIconYoutubeModule,
  SvgIconIsKYCModule,
  SvgIconDownloadFileModule,
  SvgIconEditModule,
  SvgIconWhatsappModule,
];

const components = [
  WizardProgressComponent,
  WizardProgressHorizontalComponent,
  WizardStepFooterComponent,
  RatesSocialComponent,
  InfoComponent,
  SocialLabelComponent,
  CalendarComponent,
  CampaignStatusLabelComponent,
  NgSelectMultiLabelComponent,
  RadioComponent,
  BriefProposalStatusLabelComponent,
  NumberViewComponent,
  ProfilePublicComponent,
  FileUploadComponent,
  FileUploadDefaultComponent,
  PopupInfoComponent,
  SubmitContentPopupComponent,
  DetailContentPopupComponent,
  ChannelProposalStatusLabelComponent,
  LangComponent,
  SubmitContentPopupPreviewComponent,
  ShowMoreComponent,
  CategoriesComponent,
  TagCreateComponent,
  LandingFooterComponent,
  GenerateLinkComponent,
  ReportSubmissionModalComponent,
  PublicHeaderComponent,
  PublicFooterComponent,
  AddItemComponent,
  ClientsSelectorComponent,
  TableNoItemsFoundComponent,
  SubmitContentPopupDateTimeComponent,
  AdminCampaignStatusComponent,
  ChangePinComponent,
  ForgotPinModalComponent,
  InputNewPinComponent,
  ForgotVerificationCodeComponent,
  ForgotButtonComponent,
  SearchCreatorsComponent,
  SearchBrandComponent,
  SearchCampaignComponent,
  ImgCropperComponent,
  ChannelProposalStatusLabelComponent,
  SliceTranslationComponent,
  CalendarMobileDateComponent,
  AssetContainerComponent,
  SourceEmptyPlaceholderComponent,
  BreadcrumbsWithLinksComponent,
  SubmitContentPopupAssetRequirementsComponent,
  UpgradeModalComponent,
  SocialTokenExpiredModalComponent,
  BrandLogoTitleComponent,
  AgencyProfileAvatarComponent,
  HowApproveContentComponent,
  ConfirmModalComponent,
  ChannelProposalStatusComponent,
  AddCreatorComponent,
  CustomSowCreatorComponent,
  CustomRateCreatorComponent,
  InviteCreatorModalComponent,
  InviteCreatorEmailComponent,
  InviteCreatorListComponent,
  InviteCreatorDetailComponent,
  ModalXlsCreatorMapComponent,
  ModalXlsCreatorFixComponent,
  ModalCsvCreatorComponent,
  ModalCsvCreatorResponseComponent,
  InviteCreatorFromOutsideComponent,
  CreatorDetailPaymentComponent,
  CreatorDetailCrmComponent,
  CreatorPerformanceComponent,
  TiktokConnectComponent,
  AgencyReportInfoLimitComponent,
  AgencyReportMetricsComponent,
  ContactLinkComponent,
  MoveBalanceComponent,
  MoveBalanceModalComponent,
  SharedCardBalanceComponent,
  BalanceDetailSharedComponent,
  TotalEarningSharedComponent,
  BalanceDetailButtonComponent,
  ModalTaxComponent,
  SubmitWithPinComponent,
  CardDashboardComponent,
  OtpComponent,
  PayCreatorsHomeModalComponent,
  AddCreatorSowComponent,
];

const directives = [
  PopupPositionDirective,
  ScrollNearEndDirective,
  TypedTemplateDirective,
  ValidateEqualDirective,
];

@NgModule({
  imports: [
    CoreModule,
    ...externalModules,
    ...primeNgModules,
    ...appLibsModules,
    ...icons,
  ],
  declarations: [...components, ...directives],
  exports: [
    CoreModule,
    ...primeNgModules,
    ...appLibsModules,
    ...icons,
    ...components,
    ...directives,
  ],
})
export class SharedModule {}
