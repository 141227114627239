import * as moment from 'moment';

import { Channels, PostTypes } from '@slc-libs/enums';
import { GetPageParams, PaginationResponse } from '@slc-libs/interfaces';

import {
  PaymentException,
  PaymentSetup,
  SignerAdditionalSOW,
  SignerSetup,
  XpressReportContractTerm,
} from '@slice-interfaces/e-signature/xpress-contract.model';
import { ContractTypesEnum } from '@slice-enums/e-signature/xpress-contract.enum';

export class XpressReportPayload {
  name: string;
  brandName: string;
  startDate: string;
  endDate: string;
  creatorList: XpressReportCreatorLists;
  contractTerm: XpressReportContractTerm;
  creatorPaymentTerm: string;

  public static generateCombinedCreatorList(
    creatorList: XpressReportCreatorLists,
  ): XpressReportCreator[] {
    const combinedCreatorList: XpressReportCreator[] = [];
    creatorList.instagram.forEach(creator => {
      combinedCreatorList.push(creator);
    });
    creatorList.youtube.forEach(creator => {
      combinedCreatorList.push(creator);
    });
    creatorList.tiktok.forEach(creator => {
      combinedCreatorList.push(creator);
    });
    return combinedCreatorList;
  }

  public static generateCreatorPostList(
    channel: Channels,
    creator: XpressReportCreator,
  ): XpressReportCreator {
    switch (channel) {
      case Channels.INSTAGRAM: {
        creator.totalPrice = 0;
        creator.postList = [
          {
            postType: PostTypes.STORIES,
            quantity: 0,
            price: XpressReportPayload.findPostPrices(
              creator,
              channel,
              PostTypes.STORIES,
            ),
            priceChanged: false,
          },
          {
            postType: PostTypes.POST,
            quantity: 0,
            price: XpressReportPayload.findPostPrices(
              creator,
              channel,
              PostTypes.POST,
            ),
            priceChanged: false,
          },
          {
            postType: PostTypes.REELS,
            quantity: 0,
            price: XpressReportPayload.findPostPrices(
              creator,
              channel,
              PostTypes.REELS,
            ),
            priceChanged: false,
          },
          {
            postType: PostTypes.CAROUSEL_ALBUM,
            quantity: 0,
            price: XpressReportPayload.findPostPrices(
              creator,
              channel,
              PostTypes.CAROUSEL_ALBUM,
            ),
            priceChanged: false,
          },
        ];
        return creator;
      }
      case Channels.YOUTUBE: {
        creator.totalPrice = 0;
        creator.postList = [
          {
            postType: PostTypes.PRODUCT_REVIEW,
            quantity: 0,
            price: XpressReportPayload.findPostPrices(
              creator,
              channel,
              PostTypes.PRODUCT_REVIEW,
            ),
            priceChanged: false,
          },
          {
            postType: PostTypes.PRODUCT_PLACEMENT,
            quantity: 0,
            price: XpressReportPayload.findPostPrices(
              creator,
              channel,
              PostTypes.PRODUCT_PLACEMENT,
            ),
            priceChanged: false,
          },
          {
            postType: PostTypes.HOST_READ_AD,
            quantity: 0,
            price: XpressReportPayload.findPostPrices(
              creator,
              channel,
              PostTypes.HOST_READ_AD,
            ),
            priceChanged: false,
          },
        ];
        return creator;
      }
      case Channels.TIKTOK: {
        creator.totalPrice = 0;
        creator.postList = [
          {
            postType: PostTypes.TIKTOK_VIDEO,
            quantity: 0,
            price: XpressReportPayload.findPostPrices(
              creator,
              channel,
              PostTypes.TIKTOK_VIDEO,
            ),
            priceChanged: false,
          },
        ];
        return creator;
      }
      default: {
        console.error('Channel not found, unable to generateCreatorPostList');
        return creator;
      }
    }
  }

  public static findPostPrices(
    creator: XpressReportCreator,
    channel: Channels,
    postType: PostTypes,
  ) {
    const defaultPrice = { price: 0 }; // CreatorSearchDetailPostPrice

    return creator?.postPrices
      ? (
          creator.postPrices.find(
            p => p.channel === channel && p.postType === postType,
          ) || defaultPrice
        ).price
      : defaultPrice.price;
  }

  public static convertPayloadReport(dto: XpressReportPayload): any {
    const payload = {
      ...dto,
      type: 'REPORT',
      pitch: true,
      estimatedBudget: 0,

      objectives: null,
      description: null,
      brandSafetyGuidelines: null,
      country: null,
      audienceGender: null,
      creatorGender: null,
      creatorAge: null,
      creators: [] as Array<string>,
      cities: [],
      creatorSizes: [],
      contentCategories: [],
      audienceAge: [],
      audienceSocio: [],
      briefs: [],
    };
    if (dto.creatorList) {
      payload.creators = [];
      for (const [key, value] of Object.entries(payload?.creatorList)) {
        value.forEach((element: XpressReportCreator) => {
          payload.creators.push(element.userId);
        });
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete payload?.creatorList;
    }
    if (dto.startDate) {
      payload.startDate = moment
        .utc(dto.startDate)
        .local()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }
    if (dto.endDate) {
      payload.endDate = moment
        .utc(dto.endDate)
        .local()
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }
    return payload;
  }
}

export interface XpressReportCreatorLists {
  [Channels.INSTAGRAM]: Array<XpressReportCreator>;
  [Channels.YOUTUBE]: Array<XpressReportCreator>;
  [Channels.TIKTOK]: Array<XpressReportCreator>;
}

export interface XpressReportCreator {
  avatar?: any;
  email: string;
  userId: string;
  channel: Channels;
  username: string;
  socialUsername?: string;
  postList: Array<XpressReportCreatorPostRequests>;
  postPrices: Array<CreatorSearchDetailPostPrice>; // default creator post price
  followersCount?: number;
  engagementRate?: number;
  cpr?: number;
  cpv?: number;
  cpi?: number;
  cpe?: number;
  totalPriceWithMarkup?: number;
  totalPrice: number; // total all (postList.price * postList.quantity)
  socialAccounts?: Array<any>;
  vat?: number;
}

export interface NewXpressReportCreator extends XpressReportCreator {
  quantity?: number;
  rate?: number;
  subNet?: number;
  publicRate?: number;
  subPublicRate?: number;
}

export interface XpressReportCreatorPostRequests {
  postType: PostTypes;
  quantity: number;
  price: number;
  priceChanged?: boolean;
  username?: string;
  followers?: number;
  publicRate?: number;
  creatorMarkupRate?: number;
}

export interface XpressReportResponse {
  id: string;
  briefId: string;
}

/* creator add dialog */
export interface CreatorSearchParams extends GetPageParams {
  roles: Array<'manager' | 'creator'>;
  name: string;
}

export interface CreatorSearchResponse extends PaginationResponse {
  content: Array<CreatorSearchDetail>;
}

export interface CreatorSearchDetail {
  id: string;
  email: string;
  userId: string;
  avatar: string;
  name: string;
  role: string;
  socialAccounts: Array<CreatorSearchDetailSocialAccount>;
  postPrices: Array<CreatorSearchDetailPostPrice>;
}

export interface CreatorSearchDetailSocialAccount {
  channel: Channels;
  followers: number;
  username?: string;
  stats: {
    channel?: Channels;
    er: number;
    cpr: number;
    cpv: number;
    cpi: number;
    cpe: number;
  };
}

export interface CreatorSearchDetailPostPrice {
  channel: Channels;
  postType: PostTypes;
  price: number;
}

export interface AddCreatorLiveCampaignModel {
  channel: Channels;
  creatorId: string;
  postList: Array<XpressReportCreatorPostRequests>;
}

export interface SowDetail {
  quantity: number;
  price: number;
}
export interface SowUserDetail {
  username: string;
  followers: string;
}

export interface CreatorList {
  email: string;
  ttVideoSow: SowDetail;
  igReelsSow: SowDetail;
  igStorySow: SowDetail;
  igPostSow: SowDetail;
  igCarouselSow: SowDetail;
  ytHostReadSow: SowDetail;
  ytProductPlacementSow: SowDetail;
  ytProductReviewSow: SowDetail;
  igAccount: SowUserDetail;
  ttAccount: SowUserDetail;
  ytAccount: SowUserDetail;
}

export interface CreatorListPayload {
  creatorList: CreatorList[];
}

export interface CreatorSowJsonToExcel {
  email: string;
  ttVideoSow: string;
  ttVideoSowprice: string | null;
  igStorySow: string;
  igStorySowprice: string | null;
  igReelsSow: string;
  igReelsSowprice: string | null;
  igPostSow: string;
  igPostSowprice: string | null;
  igCarouselSow: string;
  igCarouselSowprice: string | null;
  ytHostReadSow: string;
  ytHostReadSowprice: string | null;
  ytProductPlacementSow: string;
  ytProductPlacementSowprice: string | null;
  ytProductReviewSow: string;
  ytProductReviewSowprice: string | null;
}
