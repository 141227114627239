import { UserTypes } from '@slc-libs/enums';
import {
  getAgencyNameMock,
  getChannelMock,
  getEmailMock,
  getIdMock,
  getNumberMock,
  getPagebaleResponseMock,
  getPostTypeMock,
  getUsernameMock,
} from '@slc-libs/mocks';

import { AgencyClientsRosterFilters } from '@slice-interfaces/agency-client-roster/agency-client-roster-filters';
import { AgencyClientsRosterResponse } from '@slice-interfaces/agency-client-roster/agency-client-roster-response';
import { USER_INVITE_STATUS } from '@slice-enums/user';

import { getRandomMockAvatar } from '../../_mocks/_helpers/images';

const createAgencyUser = (): any => {
  return {
    id: getIdMock(),
    userId: getIdMock(),
    avatar: getRandomMockAvatar(),
    name: getUsernameMock(),
    role: UserTypes.CREATOR,
    socialAccounts: [
      {
        channel: getChannelMock(),
        followers: getNumberMock(100000),
      },
      {
        channel: getChannelMock(),
        followers: getNumberMock(100000),
      },
    ],
    postPrices: [
      {
        channel: getChannelMock(),
        postType: getPostTypeMock(),
        price: getNumberMock(10000),
      },
    ],
  };
};
const _getList = (
  params: AgencyClientsRosterFilters,
  res: AgencyClientsRosterResponse,
): Array<any> => {
  const list: Array<any> = [];

  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    list.push({
      invitationId: getIdMock(),
      agencyName: getAgencyNameMock(),
      picName: getUsernameMock(),
      creatorName: getUsernameMock(),
      picEmail: getEmailMock(),
      agencyUser: Math.random() > 0.5 ? createAgencyUser() : undefined,
      status:
        Math.random() > 0.5
          ? USER_INVITE_STATUS.ACCEPTED
          : USER_INVITE_STATUS.INVITED,
    });
  }
  return list;
};

export const getAgencyClientsRosterResponseMocks = (
  params: AgencyClientsRosterFilters,
): AgencyClientsRosterResponse => {
  const res = getPagebaleResponseMock(params);
  res.content = _getList(params, res as any);
  return res as AgencyClientsRosterResponse;
};
