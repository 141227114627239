import { Channels } from '@slc-libs/enums';
import { getNumberMock } from '@slc-libs/mocks';

import { CampaignBrandStats } from '@slice-interfaces/brand-campaign-details';

export const getBriefBrandStatsMock = (): CampaignBrandStats => {
  return {
    nanoCreators: getNumberMock(10),
    microCreators: getNumberMock(10),
    macroCreators: getNumberMock(10),
    megaCreators: getNumberMock(10),
    estimatedReach: getNumberMock(10),
    estimatedEngagementRate: getNumberMock(10),
    desiredBudget: getNumberMock(10),
    currentBudget: getNumberMock(10),
    recommendedBudget: getNumberMock(10),
    vat: getNumberMock(10),
    sliceFee: getNumberMock(10),
    totalAmount: getNumberMock(10),
    estimatedCpr: getNumberMock(10),
    estimatedCpe: getNumberMock(10),
    estimatedCpi: getNumberMock(10),
    totalExpectedPosts: getNumberMock(10),
    postsLive: getNumberMock(10),
    remainingPosts: getNumberMock(10),
    invoicePaidAmount: Math.random() > 0.5 ? getNumberMock(10) : 0,
    invoicePaidCurrency: 'USD',
    creatorChannelCount: {
      [Channels.INSTAGRAM]: getNumberMock(10),
      [Channels.YOUTUBE]: getNumberMock(10),
      [Channels.TIKTOK]: getNumberMock(10),
      [Channels.APPEARANCE]: getNumberMock(10),
    },
    securityCode: 'mock-secure-code',
    postTypeCount: {},
  };
};
