export { UserTypes } from '@slc-libs/enums';

export enum UserConnectionStatus {
  INVITED = 'invited',
  DECLINED = 'declined',
  ACCEPTED = 'active',
  REMOVED = 'removed',
}

export enum CreatorKYCRole {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum CreatorKycLevel {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  ID_CARD_SUBMITTED = 'ID_CARD_SUBMITTED',
  NPWP_SUBMITTED = 'NPWP_SUBMITTED',
  ALL_UPLOADED = 'ALL_UPLOADED',
  VERIFIED = 'VERIFIED',
}
