import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AgencyInvitationsHttpService } from '@slice-services/agency-invitation/agency-invitations-http.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { PaymentMethod } from '@slice-interfaces/agency-client-roster/agency-client-roster-item';
import {
  InvalidBulkCreatorData,
  InviteCreatorFormParams,
  ResponseInviteCreator,
} from '@slice-interfaces/agency-invitation/agency-invite-payload';
import { ErrorCodeBulkUploadCustomPayment } from '@slice-enums/payment-types';
import { CreatorKYCRole } from '@slice-enums/user-types.enum';

@Component({
  selector: 'slice-modal-xls-creator-fix',
  templateUrl: './modal-xls-creator-fix.component.html',
  styleUrls: ['./modal-xls-creator-fix.component.scss'],
})
export class ModalXlsCreatorFixComponent extends AbstractSubscriberComponent {
  @Input() isVisible = false;
  @Input() isLoading = false;
  @Input() data!: ResponseInviteCreator;
  @Output() fixEvent = new EventEmitter<any[] | undefined>();

  selectedIndex?: number;
  selectedCreator?: InvalidBulkCreatorData;
  isFixDialogVisible = false;

  constructor(
    private authStateS: AuthStateService,
    private messageService: MessageService,
    private agencyInvitationsHttpS: AgencyInvitationsHttpService,
  ) {
    super();
  }

  fixCreator(creator: InvalidBulkCreatorData, index: number): void {
    this.selectedCreator = { ...creator };
    this.selectedIndex = index;
    this.isFixDialogVisible = true;
  }

  updateCreator(updatedCreator: InvalidBulkCreatorData): void {
    if (updatedCreator) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.data.invalidCreators[updatedCreator['index']] = updatedCreator;
      this.selectedCreator = undefined;
      this.selectedIndex = undefined;
    }
    this.isFixDialogVisible = false;
  }

  deleteCreator(index: number): void {
    this.data.invalidCreators.splice(index, 1);
  }

  needFixes(): boolean {
    return this.data?.invalidCreators?.some(
      creator => creator.errorCodes?.length > 0,
    );
  }

  submitAll(): void {
    const formattedPayload: InviteCreatorFormParams[] =
      this.data.invalidCreators.map(creator => ({
        earnings: creator.earnings || '',
        waPhoneNumber: creator.waPhoneNumber || undefined,
        address: creator.address || '',
        kycType: creator.kycType as CreatorKYCRole,
        idCardNumber: creator.idCardNumber || '',
        companyName: creator.companyName || '',
        npwpNumber: creator.npwpNumber || '',
        name: creator.name || '',
        email: creator.email,
        bankCode: creator.bankCode || null,
        bankAccountNumber: creator.bankAccountNumber || null,
        paymentMethod: (creator.paymentMethod as PaymentMethod) || null,
        bankAccountName: null,
      }));
    this.fixEvent.emit(formattedPayload);
  }
}
