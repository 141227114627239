<button
  type="button"
  *ngIf="tooltipKey || html"
  (click)="isTouchScreen || tooltipModal ? toggleModal($event) : null"
  pTooltip="{{ html ? html : (tooltipKey | transloco) }}"
  tooltipStyleClass="{{ tooltipCssClass }}"
  [tooltipDisabled]="!!tooltipModal"
  [escape]="!html"
>
  <!-- for html css changes [hideDelay]="100000000000000" -->
  <ng-content></ng-content>
</button>

<p-dialog
  *ngIf="(isTouchScreen || tooltipModal) && isModalVisible"
  [(visible)]="isModalVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="toggleModal($event)"
>
  <p *ngIf="tooltipKey">{{ tooltipKey | transloco }}</p>
  <div *ngIf="html" [innerHTML]="html"></div>
  <p-footer>
    <button
      class="slc-btn slc-btn-outline-blue"
      (click)="toggleModal($event)"
      type="button"
    >
      <span>{{ okBtnTitle }}</span>
    </button>
  </p-footer>
</p-dialog>
