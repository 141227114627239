import { NgModule } from '@angular/core';

import { CustomOrderPipe } from './custom-order.pipe';
import { RangeDatePipe } from './date-range.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { NumberSuffixPipe } from './number-suffix.pipe';
import { RupiahPipe } from './rupiah.pipe';
import { SplitStringPipe } from './split-string.pipe';
import { TimeFormatPipe } from './time-format.pipe';

@NgModule({
  declarations: [
    CustomOrderPipe,
    RangeDatePipe,
    LinkifyPipe,
    NumberSuffixPipe,
    RupiahPipe,
    SplitStringPipe,
    TimeFormatPipe,
  ],
  exports: [
    CustomOrderPipe,
    RangeDatePipe,
    LinkifyPipe,
    NumberSuffixPipe,
    RupiahPipe,
    SplitStringPipe,
    TimeFormatPipe,
  ],
})
export class AppPipesModule {}
