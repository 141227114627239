import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { WalletBalanceHttpService } from '@slice-services/wallet-balance-http.service';
import { MessageService } from 'primeng/api';

import { PaymentMethod } from '@slice-interfaces/agency-client-roster/agency-client-roster-item';
import { InviteCreatorFormParams } from '@slice-interfaces/agency-invitation/agency-invite-payload';
import { BankList } from '@slice-interfaces/bulk-payment/campaign';
import { Select } from '@slice-interfaces/select/select';
import { CreatorKYCRole } from '@slice-enums/user-types.enum';

@Component({
  selector: 'slice-invite-creator-detail',
  templateUrl: './invite-creator-detail.component.html',
  styleUrls: ['./invite-creator-detail.component.scss'],
})
export class InviteCreatorDetailComponent implements OnInit {
  @Input() dialogData!: InviteCreatorFormParams;
  @Output() closed = new EventEmitter<InviteCreatorFormParams | undefined>();
  isVisible = true;
  isLoading = false;
  isLoadingBankList = false;
  isLoadingBankCheck = false;
  isErrorFetchName = false;

  creatorForm: FormGroup;
  accountTypes: Select[];
  paymentMethods: Select[];
  bankList: Array<BankList> = [];
  PaymentMethod = PaymentMethod;
  CreatorKYCRole = CreatorKYCRole;

  constructor(
    private fb: FormBuilder,
    private messageS: MessageService,
    private translocoS: TranslocoService,
    private walletHttpS: WalletBalanceHttpService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initSelects();
    this.loadBankList();
  }

  initForm(): void {
    this.creatorForm = this.fb.group({
      email: this.fb.control(
        { disabled: true, value: this.dialogData?.email || '' },
        {
          validators: [Validators.required, Validators.email],
        },
      ),
      name: this.fb.control(
        {
          disabled: !!this.dialogData?.userId,
          value: this.dialogData?.name || '',
        },
        { validators: !this.dialogData?.userId ? [Validators.required] : null },
      ),
      waPhoneNumber: this.fb.control(this.dialogData?.waPhoneNumber || null),
      paymentMethod: this.fb.control(this.dialogData?.paymentMethod || null),
      // BANK_TRANSFER only form
      bankCode: this.fb.control(this.dialogData?.bankCode || null),
      bankAccountNumber: this.fb.control(
        this.dialogData?.bankAccountNumber || null,
      ),
      bankAccountName: this.fb.control({
        disabled: true,
        value: this.dialogData?.bankAccountName || null,
      }),
      // BANK_TRANSFER only form
      address: this.fb.control(this.dialogData?.address || null),
      kycType: this.fb.control(this.dialogData?.kycType || null),
      idCardNumber: this.fb.control(this.dialogData?.idCardNumber || null),
      companyName: this.fb.control(this.dialogData?.companyName || null),
      npwpNumber: this.fb.control(this.dialogData?.npwpNumber || null),
      earnings: this.fb.control(this.dialogData?.earnings || null), // INDIVIDUAL only form
      avatar: this.fb.control(this.dialogData?.avatar || null),
      userId: this.fb.control(this.dialogData?.userId || null),
      creatorConsent: this.fb.control(false),
    } as Record<keyof InviteCreatorFormParams, FormControl>);

    if (this.dialogData?.paymentMethod === PaymentMethod.BANK_TRANSFER) {
      this.creatorForm
        .get('creatorConsent')
        ?.setValidators([Validators.requiredTrue]);
      this.creatorForm.get('creatorConsent')?.updateValueAndValidity();
    }
  }

  initSelects(): void {
    this.accountTypes = [
      {
        label: 'Personal',
        // this.translocoS.translate(
        //   'tags-selection.creator-role.individual-creator',
        // ),
        value: CreatorKYCRole.INDIVIDUAL,
      },
      {
        label: 'Company',
        // this.translocoS.translate(
        //   'tags-selection.creator-role.company-creator',
        // ),
        value: CreatorKYCRole.COMPANY,
      },
    ];
    this.paymentMethods = [
      // { label: 'Bank Transfer', value: PaymentMethod.BANK_TRANSFER },
      { label: 'Slice Wallet', value: PaymentMethod.SLICE_WALLET },
    ];
    if (this.dialogData.userId) {
      this.paymentMethods.push({
        label: 'Bank Transfer',
        value: PaymentMethod.BANK_TRANSFER,
      });
    }
  }

  loadBankList(): void {
    this.isLoadingBankList = true;
    this.walletHttpS
      .fetchBankAccountv2()
      // .pipe(
      //   tap(data => {
      //     const sliceWallet = {
      //       name: 'Slice Digital Wallet',
      //       code: 'slice',
      //       active: true,
      //       govBankCode: 'slice',
      //       inquirable: true,
      //     };
      //     this.bankList = [sliceWallet, ...data];
      //   }),
      // )
      .subscribe(
        data => {
          this.isLoadingBankList = false;
          this.bankList = data;
        },
        () => {
          this.isLoadingBankList = false;
          this.messageS.add({
            severity: 'error',
            detail: 'Failed to load bank list. Please try again later.',
          });
        },
      );
  }

  paymentSelected(v: Select) {
    if (v.value === PaymentMethod.BANK_TRANSFER) {
      this.creatorForm.get('bankCode')?.setValidators([Validators.required]);
      this.creatorForm
        .get('bankAccountNumber')
        ?.setValidators([Validators.required]);
      this.creatorForm
        .get('bankAccountName')
        ?.setValidators([Validators.required]);
      this.creatorForm
        .get('creatorConsent')
        ?.setValidators([Validators.requiredTrue]);
    } else {
      this.creatorForm.get('bankCode')?.clearValidators();
      this.creatorForm.get('bankAccountNumber')?.clearValidators();
      this.creatorForm.get('bankAccountName')?.clearValidators();
      this.creatorForm.get('creatorConsent')?.clearValidators();
    }
    this.creatorForm.get('bankCode')?.updateValueAndValidity();
    this.creatorForm.get('bankAccountNumber')?.updateValueAndValidity();
    this.creatorForm.get('bankAccountName')?.updateValueAndValidity();
    this.creatorForm.get('creatorConsent')?.updateValueAndValidity();
  }

  get phoneFormCtrl(): FormControl {
    return this.creatorForm.get('waPhoneNumber') as FormControl;
  }
  get bankAccountNumberCtrl(): AbstractControl {
    return this.creatorForm.get('bankAccountNumber') as AbstractControl;
  }
  get bankAccountNameCtrl(): AbstractControl {
    return this.creatorForm.get('bankAccountName') as AbstractControl;
  }

  checkBank() {
    if (this.creatorForm.get('bankCode')?.value) {
      this.isLoadingBankCheck = true;
      this.isErrorFetchName = false;
      this.walletHttpS
        .fetchAccountInquiry({
          bankCode: this.creatorForm.get('bankCode')?.value,
          accountNumber: this.creatorForm.get('bankAccountNumber')?.value,
        })
        .subscribe(
          r => {
            this.isLoadingBankCheck = false;
            this.creatorForm.get('bankAccountName')?.patchValue(r?.accountName);
          },
          error => {
            this.isLoadingBankCheck = false;
            this.isErrorFetchName = true;
          },
        );
    }
  }

  submitForm(): void {
    if (this.creatorForm.valid) {
      const formData = this.creatorForm.getRawValue();
      delete formData.creatorConsent;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formData.indexed = this.dialogData['indexed'];
      formData.waPhoneNumber = this.phoneFormCtrl?.value?.e164Number;
      this.closed.emit(formData);
    }
  }
}
