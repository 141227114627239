<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  position="{{ bS.winWidth > 768 ? 'right' : '' }}"
  (onHide)="onHide()"
>
  <p-header>
    <button
      class="btn btn-outline-light btn-back"
      type="button"
      (click)="onHide()"
    >
      <i class="pi pi-arrow-left"></i>
      <span>&nbsp;&nbsp;{{ 'common.back' | transloco }}</span>
    </button>
  </p-header>
  <div class="row">
    <div class="col-2 d-inline-flex justify-content-center align-items-center">
      <slc-avatar
        class="slc-avatar-56"
        [userFullName]="creatorDetail.fullName"
        [imagePath]="creatorDetail.avatar"
      ></slc-avatar>
    </div>
    <div
      class="d-flex align-items-center"
      [ngClass]="{ 'col-md-7 col-5': isKyc, 'col-10': !isKyc }"
    >
      <h5 class="mr-3">
        {{ creatorDetail.fullName }}
        <br />
        <span
          *ngIf="creatorDetail?.companyName"
          class="text-company slc-text-color-light"
        >
          &nbsp;({{ creatorDetail.companyName }})
        </span>
      </h5>
      <i
        class="pi fav-button mr-2"
        [ngClass]="[creatorDetail?.favorited ? 'pi-star-fill' : 'pi-star']"
        [hidden]="!creatorDetail?.agencyUserId"
        style="font-size: 20px"
        (click)="addFavorite()"
      ></i>
    </div>
    <div
      *ngIf="isKyc"
      class="col-md-3 col-5 d-flex align-items-center justify-content-end w-100"
    >
      <slc-svg-icon-iskyc></slc-svg-icon-iskyc>
    </div>
  </div>
  <div class="row mt-3 mx-1">
    <div class="col-md-8 col-8 pl-0">
      <div
        class="slc-flex-fs-c slc-flex-wrap-wrap"
        *ngIf="creatorDetail?.categories?.length"
      >
        <slc-tag
          *ngFor="let category of creatorDetail.categories"
          [titleKey]="category.name"
          class="slc-mr-8 slc-mb-8"
          [defaultName]="category.defaultName"
          [isView]="true"
          [isOutlined]="true"
        >
        </slc-tag>
      </div>
    </div>
    <div
      class="col-md-4 col-4 px-0"
      *ngIf="dataUser?.userStatus !== 'NOT_REGISTERED'"
    >
      <a
        href="{{ getRateCardUrl() }}"
        target="_blank"
        class="slc-btn slc-btn-link btn-back align-items-start p-0 w-100 justify-content-end"
        (click)="goToMediaKit()"
      >
        <span class="font-weight-bold btn-profile">Profile Insight</span>
      </a>
    </div>
  </div>
  <slice-loader *ngIf="loading" class="slice-loader-fluid"></slice-loader>
  <p-accordion [(activeIndex)]="activeTabIndex" [multiple]="true">
    <p-accordionTab header="Personal Info">
      <div class="container" *ngIf="dataUser?.userStatus === 'NOT_REGISTERED'">
        <div class="row">
          <div class="col-1">
            <i class="pi pi-envelope dark-grey"></i>
          </div>
          <p class="col-4">Email</p>
          <p class="col-7 font-weight-bold">
            {{
              creatorDetail.personalInfo?.email
                ? creatorDetail.personalInfo.email
                : '-'
            }}
          </p>
        </div>
      </div>
      <div class="container" *ngIf="dataUser?.userStatus !== 'NOT_REGISTERED'">
        <div class="row">
          <div class="col-1">
            <i class="pi pi-envelope dark-grey"></i>
          </div>
          <p class="col-4">Email</p>
          <p class="col-7 font-weight-bold">
            {{
              creatorDetail.personalInfo?.email
                ? creatorDetail.personalInfo.email
                : '-'
            }}
          </p>
        </div>
        <div class="row mt-2">
          <div class="col-1">
            <i class="pi pi-phone dark-grey"></i>
          </div>
          <p class="col-4">Phone</p>
          <p class="col-7 font-weight-bold">
            {{
              creatorDetail.personalInfo?.phone
                ? creatorDetail.personalInfo.phone
                : '-'
            }}
          </p>
        </div>
        <div class="row mt-2">
          <div class="col-1">
            <i class="pi pi-globe dark-grey"></i>
          </div>
          <p class="col-4">City & Country</p>
          <p class="col-7 font-weight-bold">
            <ng-container
              *ngIf="
                creatorDetail.personalInfo?.city &&
                  creatorDetail.personalInfo?.country;
                else emptyCity
              "
            >
              {{ creatorDetail.personalInfo.city }},
              {{ creatorDetail.personalInfo.country }}
            </ng-container>
            <ng-template #emptyCity> - </ng-template>
          </p>
        </div>
        <div class="row mt-2">
          <div class="col-1">
            <i class="pi pi-home dark-grey"></i>
          </div>
          <p class="col-4">Address</p>
          <p class="col-7 font-weight-bold">
            {{
              creatorDetail.personalInfo?.address
                ? creatorDetail.personalInfo.address
                : '-'
            }}
          </p>
        </div>
        <div class="row mt-2">
          <div class="col-1">
            <i class="pi pi-id-card dark-grey"></i>
          </div>
          <p class="col-4">Date of Birth</p>
          <p class="col-7">
            <span class="font-weight-bold">{{
              creatorDetail.personalInfo?.dateOfBirth
                ? (creatorDetail.personalInfo.dateOfBirth | date : 'd MMM yyyy')
                : '-'
            }}</span>
            <span>
              (Age
              {{ getAge(creatorDetail?.personalInfo?.dateOfBirth) }}
              years)</span
            >
          </p>
        </div>
      </div>
    </p-accordionTab>
    <hr />
    <p-accordionTab header="Recent Partnership">
      <div
        class="slc-flex-fs-c slc-flex-wrap-wrap"
        *ngIf="creatorDetail.recentPartnership"
      >
        <slc-tag
          *ngFor="let partner of creatorDetail?.recentPartnership"
          [titleKey]="partner"
          class="slc-mr-8 slc-mb-8"
          [defaultName]="partner"
          [isView]="true"
        >
        </slc-tag>
      </div>
      <p class="font-italic" [hidden]="creatorDetail.recentPartnership">
        No Recent Partnership
      </p>
    </p-accordionTab>
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div
          class="slc-flex-sb-c w-100"
          (click)="detailPaymentForm.toggle(false)"
        >
          Preferred Payment Method
          <span
            (click)="$event.stopPropagation()"
            *ngIf="activeTabIndex.includes(2)"
          >
            <a
              class="btn-link"
              [hidden]="detailPaymentForm.isEditPayment"
              (click)="detailPaymentForm.toggle(true)"
            >
              <i class="pi pi-pencil mr-2"></i>
            </a>
            <button
              type="button"
              class="slc-btn slc-btn-h-36 slc-btn-green"
              [ngClass]="{ 'slc-btn-loading': detailPaymentForm.isLoading }"
              [disabled]="detailPaymentForm.ngForm?.invalid"
              [hidden]="!detailPaymentForm.isEditPayment"
              (click)="detailPaymentForm.save()"
            >
              <span>{{ 'buttons.save' | transloco }}</span>
            </button>
          </span>
        </div>
      </ng-template>

      <slice-creator-detail-payment
        #detailPaymentForm
        [dataUser]="dataUser"
        [invitationId]="invitationId"
        (paymentUpdated)="updateLastPayment($event)"
      >
      </slice-creator-detail-payment>
    </p-accordionTab>
    <hr />
    <p-accordionTab>
      <ng-template pTemplate="header">
        Tax Documents
        <span
          *ngIf="
            creatorKyc?.activeKycType &&
            dataUser?.userStatus !== 'NOT_REGISTERED'
          "
          class="slc-text-color-light"
        >
          &nbsp;({{ creatorKyc.activeKycType | titlecase }})
        </span>
      </ng-template>
      <div
        class="px-4"
        *ngIf="creatorKyc && dataUser?.userStatus !== 'NOT_REGISTERED'"
      >
        <div
          class="row align-self-center"
          *ngIf="creatorKyc?.activeKycType === CreatorKYCRole.INDIVIDUAL"
        >
          <div class="col-6 mx-auto">
            <p class="text-dark">
              Id Card
              <span class="text-secondary"
                >({{
                  creatorKyc?.idCardImageUrl ? 'Verified' : 'Unverified'
                }})</span
              >
            </p>
            <img
              *ngIf="!creatorKyc?.idCardImageUrl"
              src="/assets/images/id-card-search.png"
              alt="idcardsearch"
              style="height: 87px"
            />
            <img
              *ngIf="creatorKyc?.idCardImageUrl"
              src="/assets/images/id-card.png"
              alt="idcardsuccess"
              style="height: 87px"
            />
            <p class="text-secondary" style="font-size: 11px">
              ID Card: {{ creatorKyc?.idCardNumber || '-' }}
            </p>
          </div>
          <div class="col-6 mx-auto">
            <p class="text-dark">
              NPWP
              <span class="text-secondary"
                >({{
                  creatorKyc?.npwpImageUrl ? 'Verified' : 'Unverified'
                }})</span
              >
            </p>
            <img
              *ngIf="!creatorKyc?.npwpImageUrl"
              src="/assets/images/npwp-search.png"
              alt="npwpsearch"
              style="height: 87px"
            />
            <img
              *ngIf="creatorKyc?.npwpImageUrl"
              src="/assets/images/npwp.png"
              alt="npwpsuccess"
              style="height: 87px"
            />
            <p class="text-secondary" style="font-size: 11px">
              NPWP Number: {{ creatorKyc?.npwpNumber || '-' }}
            </p>
          </div>
          <!-- <button class="btn btn-outline-primary btn-block my-3" type="button">
            Request creator to submit
          </button> -->
        </div>
        <div
          class="row align-self-center"
          *ngIf="creatorKyc?.activeKycType === CreatorKYCRole.COMPANY"
        >
          <div class="col-6 slc-flex-c-c flex-column">
            <p class="text-dark">
              NPWP
              <span class="text-secondary"
                >({{
                  creatorKyc?.companyNpwpImageUrl ? 'Verified' : 'Unverified'
                }})</span
              >
            </p>
            <img
              *ngIf="!creatorKyc?.companyNpwpImageUrl"
              src="/assets/images/id-card-search.png"
              alt="npwpsearch"
              style="height: 87px"
            />
            <img
              *ngIf="creatorKyc?.companyNpwpImageUrl"
              src="/assets/images/id-card.png"
              alt="npwpsuccess"
              style="height: 87px"
            />
          </div>
          <div class="col-6 m-auto">
            <div class="row mb-1">
              <p class="col-5 text-dark text-small">Company Name:</p>
              <span class="col-7 text-secondary text-small">
                {{ creatorKyc?.companyName }}
              </span>
            </div>
            <div class="row">
              <p class="col-5 text-dark text-small">NPWP Number:</p>
              <span class="col-7 text-secondary text-small">
                {{ creatorKyc?.companyNpwpNumber }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="dataUser?.userStatus === 'NOT_REGISTERED'">
        <p class="font-italic" *ngIf="!creatorKyc">No tax document uploaded</p>
        <div *ngIf="creatorKyc && creatorKyc.activeKycType === 'INDIVIDUAL'">
          <p class="text-dark">
            Id Card Number : {{ creatorKyc.idCardNumber }}
          </p>
          <p class="text-dark mt-2">NPWP No : {{ creatorKyc?.npwpNumber }}</p>
        </div>
        <div *ngIf="creatorKyc && creatorKyc.activeKycType !== 'INDIVIDUAL'">
          <p class="text-dark">Company Name : {{ creatorKyc.companyName }}</p>
          <p class="text-dark mt-2">
            NPWP No : {{ creatorKyc.companyNpwpNumber }}
          </p>
        </div>
      </div>
    </p-accordionTab>
    <hr />
    <p-accordionTab header="Yearly Earning">
      <p class="py-2">
        Yearly earnings affects tax calculation when paying creators
      </p>
      <div class="earnbox text-center p-3">
        <h5>
          {{ bulkPayment | number : '1.0-2' }}
        </h5>
        <a (click)="openEarning()"
          >See All Earnings<i
            class="pi pi-arrow-up-right"
            style="font-size: 12px; color: #434ddb"
          ></i
        ></a>
      </div>
      <!-- <p class="font-italic" [hidden]="!bulkPayment">No Recent Earning</p> -->
    </p-accordionTab>
    <hr />
    <p-accordionTab header="Rate Card">
      <div class="container-rate" [hidden]="igRateCard.length === 0">
        <div class="rate-channel-title">Instagram</div>
        <div class="social-rates slc-ml-16" *ngFor="let item of igRateCard">
          <div class="row mt-3">
            <p class="col-7 rate-title">
              {{ 'enums.post-types.' + item.postType | transloco }}
            </p>
            <p class="col-4 rate-title-bold rate-value">
              {{ item.price | number }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="container-rate slc-mt-12"
        [hidden]="tiktokRateCard.length === 0"
      >
        <div class="rate-channel-title">TikTok</div>
        <div class="social-rates slc-ml-16" *ngFor="let item of tiktokRateCard">
          <div class="row mt-3">
            <p class="col-7 rate-title">
              {{ 'enums.post-types.' + item.postType | transloco }}
            </p>
            <p class="col-4 rate-title-bold rate-value">
              {{ item.price | number }}
            </p>
          </div>
        </div>
      </div>

      <div class="container-rate slc-mt-12" [hidden]="ytRateCard.length === 0">
        <div class="rate-channel-title">YouTube</div>
        <div class="social-rates slc-ml-16" *ngFor="let item of ytRateCard">
          <div class="row mt-3">
            <p class="col-7 rate-title">
              {{ 'enums.post-types.' + item.postType | transloco }}
            </p>
            <p class="col-4 rate-title-bold rate-value">
              {{ item.price | number }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="container-rate slc-mt-12"
        [hidden]="appearanceRateCard.length === 0"
      >
        <div class="rate-channel-title">Appearance</div>
        <div
          class="social-rates slc-ml-16"
          *ngFor="let item of appearanceRateCard"
        >
          <div class="row mt-3">
            <p class="col-7 rate-title">
              {{ 'enums.post-types.' + item.postType | transloco }}
            </p>
            <p class="col-4 rate-title-bold rate-value">
              {{ item.price | number }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="dataUser?.userStatus === 'NOT_REGISTERED'">N/A</div>
    </p-accordionTab>
    <hr />
    <p-accordionTab header="Past Performance">
      <div *ngIf="dataUser?.userStatus !== 'NOT_REGISTERED'">
        <slice-creator-past-performance
          [userId]="userId"
          [userChannels]="userChannels"
          [(isLoading)]="loading"
        ></slice-creator-past-performance>
      </div>
      <div *ngIf="dataUser?.userStatus === 'NOT_REGISTERED'">N/A</div>
    </p-accordionTab>
    <hr />
    <p-accordionTab header="Creator Notes">
      <form #ngForm="ngForm" (ngSubmit)="onSubmit()">
        <textarea
          placeholder="Click to add text description (max 300 character)"
          class="slc-input slc-input-with-bg"
          rows="5"
          [(ngModel)]="notes"
          [disabled]="!creatorDetail?.agencyUserId"
          name="textArea"
          required
        ></textarea>

        <div
          class="flex slc-flex-fe-c m-0"
          [hidden]="!creatorDetail?.agencyUserId"
        >
          <button
            class="slc-btn slc-btn-outline-blue w-50 mr-2"
            (click)="onHide()"
            type="button"
          >
            <span>{{ 'buttons.cancel' | transloco }}</span>
          </button>
          <button
            class="slc-btn slc-btn-primary w-50 my-3"
            [disabled]="!ngForm.valid || notes === oldNotes"
            type="submit"
          >
            <span>{{ 'buttons.submit' | transloco }}</span>
          </button>
        </div>
      </form>
    </p-accordionTab>
  </p-accordion>
</p-dialog>
