import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { Channels } from '@slc-libs/enums';

import {
  CreatorProfile,
  KycInterface,
} from '@slice-interfaces/profile/creator-profile';
import { CreatorProfileOnpoardingPayload } from '@slice-interfaces/profile/onboarding-payload';
import { Select } from '@slice-interfaces/select/select';

import { OnboardingStep } from '../../../../_interfaces/onboarding';

@Injectable()
export class NewOnboardingStateService {
  public connectedSocials$ = new BehaviorSubject<Array<Channels>>([
    // Channels.YOUTUBE,
  ]);
  public countries$ = new BehaviorSubject<Array<Select>>([]);
  public cities$ = new BehaviorSubject<Array<Select>>([]);
  public steps$ = new BehaviorSubject<Array<OnboardingStep>>([]);
  public mainForm = new UntypedFormGroup({});
  public payload$ = new BehaviorSubject<CreatorProfileOnpoardingPayload | null>(
    null,
  );
  public showModalRatecard = new BehaviorSubject<boolean>(false);
  public profileData = new BehaviorSubject<CreatorProfile | null>(null);
  public kycData = new BehaviorSubject<KycInterface | null>(null);
}
