import { Component, HostBinding, OnInit } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { NavigationService } from '@slice-services/navigation.service';
import { StripeService } from '@slice-services/stripe-http.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { MessageService } from 'primeng/api';

import { PLANS_IDS } from '@slc-libs/constants';
import { PlanTypes } from '@slc-libs/enums';

import { environment } from '@slice-env/environment';

import { AuthenticatedUser } from '@slice-interfaces/auth/auth-user';
import { BillingPeriod } from '@slice-enums/billing-period';
import { AgencySignatureTabs } from '@slice-enums/e-signature/signature-tabs';
import { ContractTypesEnum } from '@slice-enums/e-signature/xpress-contract.enum';

export enum DIALOG_RESULT_MODAL_TYPE {
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PLAN_ACTIVE = 'PLAN_ACTIVE',
  DISCOUNT = 'DISCOUNT',
  CREATOR_INVITED = 'CREATOR_INVITED',
  CONTRACT_STANDALONE_CREATED_AGENCY = 'CONTRACT_STANDALONE_CREATED_AGENCY',
  CONTRACT_STANDALONE_SIGNED_AGENCY = 'CONTRACT_STANDALONE_SIGNED_AGENCY',
  CONTRACT_CAMPAIGN_SIGNED_AGENCY = 'CONTRACT_CAMPAIGN_SIGNED_AGENCY',
  CONTRACT_SIGNED_CREATOR = 'CONTRACT_SIGNED_CREATOR',
  CONTRACT_SIGNED_SIGNER3 = 'CONTRACT_SIGNED_SIGNER3',
  DUE = 'DUE',
  DUE_TOMORROW = 'DUE_TOMORROW',
  UPGRADE_REPORTS = 'UPGRADE_REPORTS',
  UPGRADE_CREATORS = 'UPGRADE_CREATORS',
  UPGRADE_MEMBER = 'UPGRADE_MEMBER',
  ERROR_ONBOARD = 'ERROR_ONBOARD',
  ERROR_SOCIAL = 'ERROR_SOCIAL',
  ERROR_CONNECT_YT = 'ERROR_CONNECT_YT',
  ERROR_CONNECT_IG = 'ERROR_CONNECT_IG',
  ERROR_CONNECT_IG_412 = 'ERROR_CONNECT_IG_412',
  ERROR_CONNECT_IG_PAGES = 'ERROR_CONNECT_IG_PAGES',
}

interface Config {
  imgPath: string;
  titleKey: string;
  descriptionKey: string;
  mainBtnKey: string;
  additionalBtnKey?: string;
  showInfoIcon?: boolean;
  showSupportBtn?: boolean;
  showAnnualPaymentBtn?: boolean;
  logout?: boolean;
}

@Component({
  selector: 'slice-dialog-result-modal',
  templateUrl: './dialog-result-modal.component.html',
  styleUrls: ['./dialog-result-modal.component.scss'],
})
export class DialogResultModalComponent implements OnInit {
  isVisible: boolean; // from ui-state
  dialogType: DIALOG_RESULT_MODAL_TYPE; // from ui-state

  @HostBinding('style.--image') image = '';
  public loadingMain = false;
  public loadingAdditional = false;
  public TYPES = DIALOG_RESULT_MODAL_TYPE;
  private config: Record<DIALOG_RESULT_MODAL_TYPE, Config> = {
    [DIALOG_RESULT_MODAL_TYPE.PAYMENT_SUCCESS]: {
      imgPath: "url('/assets/images/modal-header/payment-success.png')",
      titleKey: 'agency-modal.agency-payment-success.title',
      descriptionKey: 'agency-modal.agency-payment-success.description',
      mainBtnKey: 'agency-modal.agency-payment-success.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.PAYMENT_FAILED]: {
      imgPath: "url('/assets/images/modal-header/payment-failed.png')",
      titleKey: 'agency-modal.agency-payment-failed.title',
      descriptionKey: 'agency-modal.agency-payment-failed.description',
      mainBtnKey: 'agency-modal.agency-payment-failed.main-btn',
      additionalBtnKey: 'agency-modal.agency-payment-failed.additional-btn',
      showInfoIcon: true,
      showSupportBtn: true,
    },
    [DIALOG_RESULT_MODAL_TYPE.PLAN_ACTIVE]: {
      imgPath: "url('/assets/images/modal-header/payment-success.png')",
      titleKey: 'agency-modal.agency-plan-active.title',
      descriptionKey: 'agency-modal.agency-plan-active.description',
      mainBtnKey: 'agency-modal.agency-plan-active.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.DISCOUNT]: {
      imgPath: "url('/assets/images/modal-header/payment-discount.png')",
      titleKey: 'agency-modal.agency-discount.title',
      descriptionKey: 'agency-modal.agency-discount.description',
      mainBtnKey: 'agency-modal.agency-discount.main-btn',
      additionalBtnKey: 'agency-modal.agency-discount.additional-btn',
      showInfoIcon: true,
      showSupportBtn: true,
    },
    [DIALOG_RESULT_MODAL_TYPE.CREATOR_INVITED]: {
      imgPath: "url('/assets/images/modal-header/withdraw-success.png')",
      titleKey: 'agency-modal.agency-creator-invited.title',
      descriptionKey: 'agency-modal.agency-creator-invited.description',
      mainBtnKey: 'agency-modal.agency-creator-invited.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.CONTRACT_STANDALONE_CREATED_AGENCY]: {
      imgPath: "url('/assets/images/modal-header/withdraw-success.png')",
      titleKey: 'agency-modal.agency-created-contract.title',
      descriptionKey: 'agency-modal.agency-created-contract.description',
      mainBtnKey: 'agency-modal.agency-created-contract.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.CONTRACT_STANDALONE_SIGNED_AGENCY]: {
      imgPath: "url('/assets/images/modal-header/withdraw-success.png')",
      titleKey: 'agency-modal.agency-signed-contract.title',
      descriptionKey: 'agency-modal.agency-signed-contract.description',
      mainBtnKey: 'agency-modal.agency-signed-contract.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.CONTRACT_CAMPAIGN_SIGNED_AGENCY]: {
      imgPath: "url('/assets/images/modal-header/withdraw-success.png')",
      titleKey: 'agency-modal.agency-signed-contract.title',
      descriptionKey: 'agency-modal.agency-signed-contract.description',
      mainBtnKey: 'agency-modal.agency-signed-contract.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.CONTRACT_SIGNED_CREATOR]: {
      imgPath: "url('/assets/images/modal-header/withdraw-success.png')",
      titleKey: 'agency-modal.creator-signed-contract.title',
      descriptionKey: 'agency-modal.creator-signed-contract.description',
      mainBtnKey: 'agency-modal.creator-signed-contract.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.CONTRACT_SIGNED_SIGNER3]: {
      imgPath: "url('/assets/images/modal-header/withdraw-success.png')",
      titleKey: 'agency-modal.signer3-signed-contract.title',
      descriptionKey: 'agency-modal.signer3-signed-contract.description',
      mainBtnKey: 'agency-modal.signer3-signed-contract.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.DUE]: {
      imgPath: "url('/assets/images/modal-header/payment-due.png')",
      titleKey: 'agency-modal.agency-payment-due.title',
      descriptionKey: 'agency-modal.agency-payment-due.description',
      mainBtnKey: 'agency-modal.agency-payment-due.main-btn',
      additionalBtnKey: 'agency-modal.agency-payment-due.additional-btn',
      showInfoIcon: true,
      logout: true,
    },
    [DIALOG_RESULT_MODAL_TYPE.DUE_TOMORROW]: {
      imgPath: "url('/assets/images/modal-header/payment-due2.png')",
      titleKey: 'agency-modal.agency-due-tomorrow.title',
      descriptionKey: 'agency-modal.agency-due-tomorrow.description',
      mainBtnKey: 'agency-modal.agency-due-tomorrow.main-btn',
      additionalBtnKey: 'agency-modal.agency-due-tomorrow.additional-btn',
      showInfoIcon: true,
      showSupportBtn: true,
    },
    [DIALOG_RESULT_MODAL_TYPE.UPGRADE_REPORTS]: {
      imgPath: "url('/assets/images/modal-header/limit-report.png')",
      titleKey: 'agency-modal.agency-upgrade-reports.title',
      descriptionKey: 'agency-modal.agency-upgrade-reports.description',
      mainBtnKey: 'agency-modal.agency-upgrade-reports.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.UPGRADE_CREATORS]: {
      imgPath:
        "url('/assets/images/modal-header/limit-creators.png'), radial-gradient(50% 50% at 50% 50%, rgba(67, 77, 219, 0.5), rgba(185, 184, 248, 0.5) 100%)",
      titleKey: 'agency-modal.agency-upgrade-creators.title',
      descriptionKey: 'agency-modal.agency-upgrade-creators.description',
      mainBtnKey: 'agency-modal.agency-upgrade-creators.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.UPGRADE_MEMBER]: {
      imgPath:
        "url('/assets/images/modal-header/add-member.png'), radial-gradient(50% 50% at 50% 50%, rgba(67, 77, 219, 0.5), rgba(185, 184, 248, 0.5) 100%)",
      titleKey: 'agency-modal.agency-upgrade-members.title',
      descriptionKey: 'agency-modal.agency-upgrade-members.description',
      mainBtnKey: 'agency-modal.agency-upgrade-members.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.ERROR_ONBOARD]: {
      imgPath:
        "url('/assets/images/modal-header/onboard-rate.png'), radial-gradient(50% 50% at 50% 50%, rgba(67, 77, 219, 0.5), rgba(185, 184, 248, 0.5) 100%)",
      titleKey: 'agency-modal.creator-error-onboard.title',
      descriptionKey: 'agency-modal.creator-error-onboard.description',
      mainBtnKey: 'agency-modal.creator-error-onboard.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.ERROR_SOCIAL]: {
      imgPath: "url('/assets/images/modal-header/triangle-warning.png')",
      titleKey: 'agency-modal.creator-error-social.title',
      descriptionKey: 'agency-modal.creator-error-social.description',
      mainBtnKey: 'agency-modal.creator-error-social.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_YT]: {
      imgPath: "url('/assets/images/modal-header/triangle-warning.png')",
      titleKey: 'agency-modal.creator-error-connect-yt.title',
      descriptionKey: 'agency-modal.creator-error-connect-yt.description',
      mainBtnKey: 'agency-modal.creator-error-connect-yt.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG]: {
      imgPath: "url('/assets/images/modal-header/triangle-warning.png')",
      titleKey: 'agency-modal.creator-error-connect-ig.title',
      descriptionKey: 'agency-modal.creator-error-connect-ig.description',
      mainBtnKey: 'agency-modal.creator-error-connect-ig.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG_412]: {
      imgPath: "url('/assets/images/modal-header/triangle-warning.png')",
      titleKey: 'agency-modal.creator-error-connect-ig.title',
      descriptionKey: 'agency-modal.creator-error-connect-ig.description-412',
      mainBtnKey: 'agency-modal.creator-error-connect-ig.main-btn',
    },
    [DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG_PAGES]: {
      imgPath: "url('/assets/images/modal-header/triangle-warning.png')",
      titleKey: 'agency-modal.creator-error-connect-ig.title',
      descriptionKey: 'agency-modal.creator-error-connect-ig.description-pages',
      mainBtnKey: 'agency-modal.creator-error-connect-ig.main-btn',
    },
  };
  public currConfig: Config;

  constructor(
    public authStateService: AuthStateService,
    public stripeService: StripeService,
    private navigationS: NavigationService,
    private uiStateS: UiStateService,
    private mS: MessageService,
    private tS: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.uiStateS.selectDialogSliceModal().subscribe(v => {
      if (v && v in DIALOG_RESULT_MODAL_TYPE) {
        this.isVisible = true;
        this.dialogType = v;
        this.currConfig = this.config[v];
        this.image = this.config[v]?.imgPath;
      } else {
        this.isVisible = false;
      }
    });
  }

  mainAction(): void {
    this.loadingMain = true;
    switch (this.dialogType) {
      case DIALOG_RESULT_MODAL_TYPE.ERROR_SOCIAL:
      case DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_YT:
      case DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG:
      case DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG_412:
      case DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG_PAGES:
      case DIALOG_RESULT_MODAL_TYPE.PAYMENT_SUCCESS:
      case DIALOG_RESULT_MODAL_TYPE.PAYMENT_FAILED:
      case DIALOG_RESULT_MODAL_TYPE.PLAN_ACTIVE:
      case DIALOG_RESULT_MODAL_TYPE.DISCOUNT:
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_SIGNED_SIGNER3:
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_STANDALONE_SIGNED_AGENCY:
        this.loadingMain = false;
        this.closeDialog();
        break;
      case DIALOG_RESULT_MODAL_TYPE.ERROR_ONBOARD:
        this.goToCreatorOnboard();
        break;
      case DIALOG_RESULT_MODAL_TYPE.CREATOR_INVITED:
        this.goCreateCampaign();
        break;
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_STANDALONE_CREATED_AGENCY:
        this.goToAgencyIndividualContract();
        break;
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_CAMPAIGN_SIGNED_AGENCY:
        this.goToAgencyCampaignContract();
        break;
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_SIGNED_CREATOR:
        this.goToCreatorContract();
        break;
      case DIALOG_RESULT_MODAL_TYPE.DUE:
      case DIALOG_RESULT_MODAL_TYPE.DUE_TOMORROW:
        // without destroy !
        this.stripeService
          .goToAgencyPayment({
            planId: PLANS_IDS[environment.envType][PlanTypes.Standard],
            billingPeriod: BillingPeriod.monthly,
          })
          .subscribe(
            () => {
              this.loadingMain = false;
              this.closeDialog();
            },
            () => {
              this.showError();
              this.loadingMain = false;
            },
          );
        break;
      case DIALOG_RESULT_MODAL_TYPE.UPGRADE_CREATORS:
      case DIALOG_RESULT_MODAL_TYPE.UPGRADE_REPORTS:
      case DIALOG_RESULT_MODAL_TYPE.UPGRADE_MEMBER:
        this.goToUpgradePlan();
        break;
      default:
        alert('Unknown dialog type');
    }
  }

  additionalAction(): void {
    this.loadingAdditional = true;
    switch (this.dialogType) {
      case DIALOG_RESULT_MODAL_TYPE.ERROR_SOCIAL:
      case DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_YT:
      case DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG:
      case DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG_412:
      case DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG_PAGES:
      case DIALOG_RESULT_MODAL_TYPE.ERROR_ONBOARD:
      case DIALOG_RESULT_MODAL_TYPE.PAYMENT_SUCCESS:
      case DIALOG_RESULT_MODAL_TYPE.PAYMENT_FAILED:
      case DIALOG_RESULT_MODAL_TYPE.PLAN_ACTIVE:
      case DIALOG_RESULT_MODAL_TYPE.DISCOUNT:
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_SIGNED_SIGNER3:
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_STANDALONE_SIGNED_AGENCY:
        this.loadingAdditional = false;
        this.closeDialog();
        break;
      case DIALOG_RESULT_MODAL_TYPE.CREATOR_INVITED:
        this.goCreateCampaign();
        break;
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_STANDALONE_CREATED_AGENCY:
        this.goToAgencyIndividualContract();
        break;
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_CAMPAIGN_SIGNED_AGENCY:
        this.goToAgencyCampaignContract();
        break;
      case DIALOG_RESULT_MODAL_TYPE.CONTRACT_SIGNED_CREATOR:
        this.goToCreatorContract();
        break;
      case DIALOG_RESULT_MODAL_TYPE.DUE:
      case DIALOG_RESULT_MODAL_TYPE.DUE_TOMORROW:
        // without destroy !
        this.stripeService
          .goToAgencyPayment({
            planId: PLANS_IDS[environment.envType][PlanTypes.Standard],
            billingPeriod: BillingPeriod.annual,
          })
          .subscribe(
            () => {
              this.loadingAdditional = false;
              this.closeDialog();
            },
            () => {
              this.showError();
              this.loadingAdditional = false;
            },
          );
        break;
      case DIALOG_RESULT_MODAL_TYPE.UPGRADE_CREATORS:
      case DIALOG_RESULT_MODAL_TYPE.UPGRADE_REPORTS:
      case DIALOG_RESULT_MODAL_TYPE.UPGRADE_MEMBER:
        this.goToUpgradePlan();
        break;
      default:
        alert('Unknown dialog type');
    }
  }

  closeDialog(): void {
    this.uiStateS.closeSliceModal();
  }

  logout(): void {
    this.authStateService.logout();
    this.navigationS.goToSignIn();
  }

  showError(): void {
    this.mS.add({
      severity: 'error',
      life: 5 * 1000,
      detail: this.tS.translate('common.error-500'),
    });
  }

  goCreateCampaign(): void {
    this.closeDialog();
    this.loadingMain = false;
    this.loadingAdditional = false;
    this.navigationS.goToCampaignCreate();
  }

  goToUpgradePlan(): void {
    this.closeDialog();
    this.loadingMain = false;
    this.loadingAdditional = false;
    this.uiStateS.showAgencyPricing(true);
    // this.navigationS.goToUpgradePlan();
  }

  goToAgencyRoster(): void {
    this.closeDialog();
    this.loadingMain = false;
    this.loadingAdditional = false;
    this.navigationS.goToAgencyRoster();
  }

  goToAgencyCampaignContract(): void {
    this.closeDialog();
    this.loadingMain = false;
    this.loadingAdditional = false;
    this.navigationS.goToContractCampaign();
  }

  goToAgencyIndividualContract(): void {
    this.closeDialog();
    this.loadingMain = false;
    this.loadingAdditional = false;
    this.navigationS.goToContractIndividual();
  }

  goToCreatorContract(): void {
    this.closeDialog();
    this.loadingMain = false;
    this.loadingAdditional = false;
    this.navigationS.goToCreatorContract();
  }

  goToCreatorOnboard(): void {
    this.closeDialog();
    this.loadingMain = false;
    this.loadingAdditional = false;

    // set onboarding to not finished
    this.authStateService.setUserStatusOnboard(false);
    // return to home and recalled Observable to update the dialog
    this.navigationS.goToAgencyHome().then(() => {
      this.authStateService.setAuthenticatedUser(
        this.authStateService.getAuthenticatedUser() as AuthenticatedUser,
      );
    });
  }
}
