import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  AgencyInvitationPayload,
  InviteCreatorFormParams,
} from '@slice-interfaces/agency-invitation/agency-invite-payload';
import { User } from '@slice-interfaces/clients-selector/clients-selector';

@Component({
  selector: 'slice-invite-creator-list',
  templateUrl: './invite-creator-list.component.html',
  styleUrls: ['./invite-creator-list.component.scss'],
})
export class InviteCreatorListComponent {
  @Input() creatorList: AgencyInvitationPayload;
  @Output() clientsEdited = new EventEmitter<{
    isInternal: boolean;
    userData: InviteCreatorFormParams;
    arrayIndex: number;
  }>();
  @Output() clientsRemoved = new EventEmitter<{
    isInternal: boolean;
    userData: InviteCreatorFormParams;
  }>();

  editInternalClient(user: InviteCreatorFormParams, index: number): void {
    this.clientsEdited.emit({
      isInternal: true,
      userData: user,
      arrayIndex: index,
    });
  }

  editExternalClient(user: InviteCreatorFormParams, index: number): void {
    this.clientsEdited.emit({
      isInternal: false,
      userData: user,
      arrayIndex: index,
    });
  }

  removeInternalClient(user: InviteCreatorFormParams): void {
    this.clientsRemoved.emit({ isInternal: true, userData: user });
  }

  removeExternalClient(user: InviteCreatorFormParams): void {
    this.clientsRemoved.emit({ isInternal: false, userData: user });
  }
}
