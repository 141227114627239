<div>
  <div class="list-box">
    <b>{{ 'agency.client-roster.add-new-client.list-in-base' | transloco }}</b>
    <p class="grey-title">
      {{ 'agency.client-roster.add-new-client.list-in-base-msg' | transloco }}
    </p>
    <div
      class="d-inline-flex flex-wrap"
      *ngIf="creatorList?.internalCreators?.length; else emptyValueTmpl"
    >
      <ng-container
        *ngFor="let user of creatorList?.internalCreators; let i = index"
      >
        <slc-tag
          class="slc-mt-8 slc-mr-4"
          [defaultName]="user?.name"
          [isDeletable]="true"
          [isGreen]="true"
          [beforeTmpl]="avatarTmpl"
          (changed)="editInternalClient(user, i)"
          (remove)="removeInternalClient(user)"
        ></slc-tag>
        <ng-template #avatarTmpl>
          <slc-avatar
            [userFullName]="user?.name"
            [imagePath]="user?.avatar"
            class="slc-mr-8 slc-avatar-24 slc-avatar-green"
          ></slc-avatar>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="list-box">
    <b>{{ 'agency.client-roster.add-new-client.list-out-base' | transloco }}</b>
    <p class="grey-title">
      {{ 'agency.client-roster.add-new-client.list-out-base-msg' | transloco }}
    </p>
    <div
      class="d-inline-flex flex-wrap"
      *ngIf="creatorList?.externalCreators?.length; else emptyValueTmpl"
    >
      <ng-container
        *ngFor="let user of creatorList?.externalCreators; let i = index"
      >
        <slc-tag
          class="slc-mt-8 slc-mr-4"
          [defaultName]="user?.email"
          [isDeletable]="true"
          [isGray]="true"
          (changed)="editExternalClient(user, i)"
          (remove)="removeExternalClient(user)"
        ></slc-tag>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #emptyValueTmpl>
  <div class="empty-box">
    <img class="empty-list" src="/assets/svg/empty-list.svg" alt="" />
    <p>{{ 'agency.client-roster.add-new-client.empty-msg' | transloco }}</p>
  </div>
</ng-template>
