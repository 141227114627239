import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AgencyInvitationsHttpService } from '@slice-services/agency-invitation/agency-invitations-http.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { MixPanelService } from '@slice-services/mix-panel.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@slice-env/environment';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { DIALOG_RESULT_MODAL_TYPE } from '@slice-shared/components/dialog-result-modal/dialog-result-modal.component';
import {
  AgencyInvitationPayload,
  InviteCreatorFormParams,
} from '@slice-interfaces/agency-invitation/agency-invite-payload';
import { BreadcrumbsWithoutLinksItem } from '@slice-interfaces/breadcrumbs-without-links';
import {
  ClientsSelectionModel,
  User,
} from '@slice-interfaces/clients-selector/clients-selector';
import { MIX_PANEL_EVENTS } from '@slice-enums/mix-panel-events.enum';

@Component({
  selector: 'slice-invite-creator-modal',
  templateUrl: './invite-creator-modal.component.html',
  styleUrls: ['./invite-creator-modal.component.scss'],
})
export class InviteCreatorModalComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() databaseAmount: number | null;
  @Output() closed = new EventEmitter<boolean>();

  public limitAmount: number;
  public isVisible = true;
  public isVisibleModalCsv = false;
  public loading = false;
  public formCreator: AgencyInvitationPayload;
  public formCreatorModal: InviteCreatorFormParams | null;
  public headerBreadcrumbs: Array<BreadcrumbsWithoutLinksItem> = [
    { titleKey: 'page-titles.client-roster' },
    { titleKey: 'buttons.add-new-client' },
  ];

  constructor(
    private tS: TranslocoService,
    private uiStateS: UiStateService,
    private messageS: MessageService,
    private authStateS: AuthStateService,
    private agencyInvitationsHttp: AgencyInvitationsHttpService,
    private mpS: MixPanelService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.limitAmount = this.authStateS.getAuthenticatedUser()
      ?.creators as number;
  }

  private initForm(): void {
    this.formCreator = {
      internalCreators: [],
      externalCreators: [],
    };
  }

  get currAmount(): number {
    const am =
      (this.databaseAmount || 0) +
      (this.formCreator?.internalCreators?.length || 0) +
      (this.formCreator?.externalCreators?.length || 0);
    return am;
  }

  get isEmptyList(): boolean {
    const internalEmpty = this.formCreator?.internalCreators?.length === 0;
    const externalEmpty = this.formCreator?.externalCreators?.length === 0;
    return internalEmpty && externalEmpty;
  }

  get isLimitReached(): boolean {
    const isReached = this.currAmount >= this.limitAmount;
    return isReached;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  clientsAdded(d: ClientsSelectionModel): void {
    if (this.isLimitReached) {
      this.showUpgrade();
    } else {
      this.openModalDetail(d.value as any);
      /*if (d.label === 'internal') {
        this.formCreator.internalCreators.push(d.value);
      } else if (d.label === 'external') {
        this.formCreator.externalCreators.push(d.value);
      } else {
        console.error('Unknown selection', d);
      }*/
    }
  }

  clientsEdited(d: {
    isInternal: boolean;
    userData: InviteCreatorFormParams;
    arrayIndex: number;
  }): void {
    if (this.loading) {
      return;
    }
    this.openModalDetail(d.userData as any, [
      d.isInternal ? 'internalCreators' : 'externalCreators',
      d.arrayIndex,
    ]);
  }

  clientsRemoved(d: {
    isInternal: boolean;
    userData: InviteCreatorFormParams;
  }): void {
    if (this.loading) {
      return;
    }
    if (d.isInternal && d.userData.userId) {
      this.formCreator.internalCreators =
        this.formCreator.internalCreators?.filter(
          i => i.userId !== d.userData.userId,
        );
    } else {
      this.formCreator.externalCreators =
        this.formCreator.externalCreators.filter(
          i => i.email !== d.userData.email,
        );
    }
  }

  showUpgrade(): void {
    this.uiStateS.showSliceModal(DIALOG_RESULT_MODAL_TYPE.UPGRADE_CREATORS);
  }

  openModalCsv(): void {
    this.isVisibleModalCsv = true;
  }
  closeCsv(): void {
    this.isVisible = false;
    this.isVisibleModalCsv = false;
  }

  openModalDetail(
    userData: InviteCreatorFormParams,
    indexed?: [string, number], // ['internalCreators', 0] or ['externalCreators', 7]
  ): void {
    if (indexed) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userData['indexed'] = indexed;
    }
    this.formCreatorModal = userData;
  }

  closeModalDetail(userData?: InviteCreatorFormParams): void {
    this.formCreatorModal = null;
    if (userData) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isEdit = userData['indexed'];
      if (isEdit) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete userData['indexed'];
        switch (isEdit[0]) {
          case 'internalCreators':
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.formCreator.internalCreators[isEdit[1]] = userData as any;
            break;
          case 'externalCreators':
            this.formCreator.externalCreators[isEdit[1]] = userData as any;
            break;
          default:
            break;
        }
      } else {
        if (userData.userId) {
          this.formCreator.internalCreators?.push(userData as any);
        } else {
          this.formCreator.externalCreators.push(userData as any);
        }
      }
    }
  }

  submit(): void {
    if (this.isLimitReached) {
      this.showUpgrade();
    } else {
      this.mpS.track(MIX_PANEL_EVENTS.agency_invites_creator);
      this.loading = true;
      this.agencyInvitationsHttp
        .inviteUsers(
          this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
          this.formCreator,
          environment.mocks?.agency.clientsRoster.invite,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.loading = false;
            this.closed.emit(true);
            this.uiStateS.showSliceModal(
              DIALOG_RESULT_MODAL_TYPE.CREATOR_INVITED,
            );
            // this.messageS.add({
            //   severity: 'success',
            //   life: 5 * 1000,
            //   detail: this.tS.translate(
            //     'agency.client-roster.add-new-client.users-invited',
            //   ),
            // });
          },
          err => {
            this.loading = false;
            if (err.status === 412) {
              this.uiStateS.showSliceModal(
                DIALOG_RESULT_MODAL_TYPE.UPGRADE_CREATORS,
              );
            } else {
              this.messageS.add({
                severity: 'error',
                life: 5 * 1000,
                detail: this.tS.translate('common.error-500'),
              });
            }
          },
        );
    }
  }
}
