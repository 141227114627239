import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';
import { MekariHttpService } from '@slice-services/mekari-http.service';
import { NavigationService } from '@slice-services/navigation.service';
import { MessageService } from 'primeng/api';
import { throwError } from 'rxjs';

import { SharedModule } from '@slice-shared/shared.module';
import { AvailableIntegration } from '@slice-enums/integration/available-integration.enum';

@Component({
  selector: 'slice-integration-mekari',
  templateUrl: './integration-mekari.component.html',
  styleUrls: ['./integration-mekari.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class IntegrationMekariComponent implements OnInit {
  constructor(
    private mS: MessageService,
    private tS: TranslocoService,
    private route: ActivatedRoute,
    private mekariS: MekariHttpService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      const token = params['access_token'];

      // console.log('code', code, 'token', token);
      if (token) {
        this.mekariS.saveMekariToken(token).subscribe(
          response => {
            this.navigationService
              .goToIntegrationConfigSetup(AvailableIntegration.MEKARI_JURNAL)
              .then(() => {
                this.mS.add({
                  summary: 'Journal is Connected',
                  severity: 'success',
                  life: 5 * 1000,
                  detail: 'Finish setting up by configuring your options',
                });
              });
          },
          error => {
            this.navigationService.goToAgencyHome().then(() => {
              this.mS.add({
                severity: 'error',
                life: 5 * 1000,
                detail: 'Error while saving Mekari Journal token',
              });
            });
            return throwError(error);
          },
        );
      } else {
        this.navigationService.goToAgencyHome().then(() => {
          this.mS.add({
            severity: 'error',
            life: 5 * 1000,
            detail: 'Empty/Invalid Mekari Journal token',
          });
        });
      }
    });
  }
}
