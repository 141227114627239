export enum MekariJurnalFeature {
  CREATOR_PAYMENT = 'creatorPayment',
  CONTRACT_METERAI = 'contract',
  BALANCE_TOPUP = 'topUp',
}

export enum MekariJurnalTransaction {
  JURNAL_CATEGORY = 'journalCategory',
  CASH_ACCOUNT = 'cashAccount',
  PAYMENT_METHOD = 'paymentMethod',
  PRODUCT_ACCOUNT = 'productBuyAccount',
  EXPENSE_ACCOUNT = 'expenseAccount',
  SOURCE_ACCOUNT = 'sourceAccount',
  DESTINATION_ACCOUNT = 'destinationAccount',

  // PAYMENT_TRANSACTION = 'PAYMENT_TRANSACTION',
  // PAYMENT_CREATOR = 'PAYMENT_CREATOR',
  // PAYMENT_FEE = 'PAYMENT_FEE',

  // METERAI_FEE = 'METERAI_FEE',
  // CONTRACT_FEE = 'CONTRACT_FEE',
  // CONTRACT_METERAI_FEE = 'CONTRACT_METERAI_FEE',

  // TOPUP_SLICE = 'TOPUP_SLICE',
  // TOPUP_FEE = 'TOPUP_FEE',
}

export enum MekariJurnalInfoWidget {
  FULL_REPORT = 'fullReport',
  SOURCE_ACCOUNT = 'sourceAccount',
  DESTINATION_ACCOUNT = 'destinationAccount',
  TOPUP_FEE = 'TOPUP_FEE',
  NET_AMOUNT = 'netAmount',
  FEE_AMOUNT = 'feeAmount',
}
