<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="!isVisibleResponse && !isVisibleDialogFix ? closed.emit() : null"
>
  <p-header>
    <span class="grey-title text-left">Import Creator</span>
  </p-header>

  <main>
    <div
      class="section blue"
      [class.drag-over]="isDragOver"
      (drop)="onFileDropped($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
    >
      <div class="step">
        <span class="step-number">1</span>
        <div class="step-content">
          <h3>Download template file</h3>
          <p>
            To import the data correctly, avoid using a template other than
            provided. This template file has been aligned with the system
            requirements.
          </p>
          <button
            type="button"
            class="slc-btn slc-btn-link p-0"
            (click)="downloadFile()"
          >
            <slc-svg-download-file-logo></slc-svg-download-file-logo>
            <span class="ml-2 btn-download"> Download template </span>
          </button>
        </div>
      </div>

      <hr />

      <div class="step">
        <span class="step-number">2</span>
        <div class="step-content">
          <h3>Upload template file</h3>
          <p>
            You can upload the previously filled template file without changing
            its format (.xlsx)
          </p>
          <div class="text-left">
            <button
              type="button"
              class="slc-btn slc-btn-primary"
              (click)="fileInput.click()"
            >
              <span>{{ selectedFile ? 'Re-Upload' : 'Upload' }}</span>
            </button>
            <div *ngIf="selectedFile" class="file-info">
              <p>{{ selectedFile.name }}</p>
            </div>
            <input
              type="file"
              #fileInput
              (change)="onFileSelected(fileInput)"
              accept=".xlsx, .xls"
              style="display: none"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <button
    type="button"
    [ngClass]="{
      'slc-btn-loading': isLoading
    }"
    [disabled]="!selectedFile"
    class="slc-btn slc-btn-outline-blue w-100 mt-4 slc-text-color-primary"
    (click)="readFile()"
  >
    Import File
  </button>
</p-dialog>

<ng-container *ngIf="isVisibleDialogFix">
  <slice-modal-xls-creator-fix
    [isVisible]="isVisibleDialogFix"
    [data]="responseData"
    (fixEvent)="eventErrorFixModal($event)"
  ></slice-modal-xls-creator-fix>
</ng-container>

<ng-container *ngIf="isVisibleResponse">
  <slice-modal-csv-creator-response
    [isVisible]="isVisibleResponse"
    [data]="responseData"
    (closed)="closeResponseModal()"
  ></slice-modal-csv-creator-response>
</ng-container>
