import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { ClientsRosterHttpService } from '@slice-services/clients-roster/clients-roster-http.service';
import { WalletBalanceHttpService } from '@slice-services/wallet-balance-http.service';
import { MessageService } from 'primeng/api';

import {
  AgencyClientPaymentInfo,
  AgencyClientsRosterItem,
  PaymentMethod,
} from '@slice-interfaces/agency-client-roster/agency-client-roster-item';
import {
  BankList,
  DetailCampaignCreatorResponse,
} from '@slice-interfaces/bulk-payment/campaign';

@Component({
  selector: 'slice-creator-detail-payment',
  templateUrl: './creator-detail-payment.component.html',
  styleUrls: ['./creator-detail-payment.component.scss'],
})
export class CreatorDetailPaymentComponent implements OnChanges {
  @Input() dataUser?: AgencyClientsRosterItem | DetailCampaignCreatorResponse;
  @Input() invitationId: string;
  @Input() isEditPayment: boolean;
  @Output() isEditPaymentChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() paymentUpdated = new EventEmitter<{
    paymentInfo: AgencyClientPaymentInfo;
    bankName?: string;
  }>();
  @ViewChild('ngForm') ngForm: NgForm;

  PaymentMethod = PaymentMethod;

  isLoading = false;
  isErrorFetchName = false;
  bankList: Array<BankList> = [];
  paymentInfo: AgencyClientPaymentInfo = {
    bankCode: null,
    bankAccountName: null,
    bankAccountNumber: null,
  };

  constructor(
    private messageS: MessageService,
    private walletHttpS: WalletBalanceHttpService,
    private clientsRosterHttpS: ClientsRosterHttpService,
  ) {}

  ngOnChanges(): void {
    this.loadBankList();
    this.toggle(this.isEditPayment);
  }

  loadBankList(): void {
    this.walletHttpS.fetchBankAccountv2().subscribe(
      banks => {
        const sliceWallet: BankList = {
          name: 'Slice Digital Wallet',
          code: 'slice',
          active: true,
          govBankCode: 'slice',
          inquirable: true,
        };
        this.bankList = [sliceWallet, ...banks];
      },
      () => {
        this.messageS.add({
          severity: 'error',
          detail: 'Failed to load bank list. Please try again.',
        });
      },
    );
  }

  checkBank(): void {
    const { bankCode, bankAccountNumber } = this.paymentInfo;

    if (bankCode && bankAccountNumber) {
      this.isLoading = true;
      this.isErrorFetchName = false;
      this.walletHttpS
        .fetchAccountInquiry({ bankCode, accountNumber: bankAccountNumber })
        .subscribe(
          response => {
            this.paymentInfo.bankAccountName = response?.accountName || null;
            this.isLoading = false;
          },
          () => {
            this.isErrorFetchName = true;
            this.isLoading = false;
          },
        );
    }
  }

  toggle(v: boolean): void {
    this.isEditPayment = v;
    this.isEditPaymentChange.emit(v);
    if (this.dataUser && v) {
      const paymentMethod =
        this.dataUser.lastPaymentMethod ?? this.dataUser.activePaymentMethod;
      this.paymentInfo = {
        bankCode:
          paymentMethod === PaymentMethod.SLICE_WALLET
            ? 'slice'
            : paymentMethod === PaymentMethod.BANK_TRANSFER &&
              this.dataUser.bankDetail?.govBankCode
            ? this.dataUser.bankDetail.govBankCode
            : null,
        bankAccountName: this.dataUser?.bankAccountName ?? null,
        bankAccountNumber: this.dataUser?.bankAccountNumber ?? null,
      };
    } else {
      this.paymentInfo = {} as any;
    }
  }

  save(): void {
    if (this.paymentInfo.bankCode === 'slice') {
      this.paymentInfo = {
        paymentMethod: PaymentMethod.SLICE_WALLET,
        bankAccountNumber: null,
        bankAccountName: null,
        bankCode: null,
      };
    } else {
      if (!this.paymentInfo.bankAccountName) {
        return;
      }
      this.paymentInfo = {
        ...this.paymentInfo,
        paymentMethod: PaymentMethod.BANK_TRANSFER,
      };
    }

    if (
      this.paymentInfo.paymentMethod === PaymentMethod.SLICE_WALLET ||
      (this.paymentInfo.paymentMethod === PaymentMethod.BANK_TRANSFER &&
        this.paymentInfo.bankAccountNumber &&
        this.paymentInfo.bankAccountName)
    ) {
      this.isLoading = true;
      this.clientsRosterHttpS
        .putAgencyRosterPaymentInfo(this.invitationId, this.paymentInfo)
        .subscribe(
          () => {
            this.messageS.add({
              severity: 'success',
              detail: 'Payment details updated successfully!',
            });
            this.paymentUpdated.emit({
              paymentInfo: this.paymentInfo,
              bankName: this.bankList.find(
                bank => bank.govBankCode === this.paymentInfo.bankCode,
              )?.name,
            });
            this.isEditPayment = false;
            this.isEditPaymentChange.emit(false);
            this.isLoading = false;
          },
          () => {
            this.messageS.add({
              severity: 'error',
              detail: 'Failed to update payment details. Please try again.',
            });
            this.isLoading = false;
          },
        );
    } else {
      this.messageS.add({
        severity: 'error',
        detail: 'Please complete all required fields.',
      });
    }
  }
}
