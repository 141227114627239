<button
  class="ml-2"
  type="button"
  (click)="openDialog()"
  [disabled]="isDisable"
>
  <slc-svg-icon-file-detail [isDisable]="isDisable"></slc-svg-icon-file-detail>
</button>

<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
>
  <p-header>
    <slice-breadcrumbs-without-links
      [items]="breadcrumbs"
      [highlightLast]="false"
    ></slice-breadcrumbs-without-links>
  </p-header>

  <div class="no-connected" *ngIf="!channelAccountConnected">
    <p>
      This creator already submitted the post, but you can't see it because
      their {{ channel }} account already
      <span class="font-weight-bold">Disconnected</span> from their Slice
      account
    </p>
  </div>
  <div class="slc-pos-rel" *ngIf="channelAccountConnected">
    <div *ngIf="channel === Channels.YOUTUBE">
      <div class="mb-4 d-flex flex-column">
        <div class="mx-auto" *ngIf="sanitizedVideoUrl; else errorView">
          <iframe
            width="560"
            height="315"
            [src]="sanitizedVideoUrl"
            title="Preview Video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div class="h-100 d-flex mx-auto mt-3" *ngIf="sanitizedVideoUrl">
          <a
            href="{{ youtubeUrl }}"
            target="_blank"
            class="btn btn-outline-primary mx-3 my-2"
            >{{ 'go-content' | transloco }}</a
          >
        </div>
      </div>
    </div>

    <div *ngIf="channel !== Channels.YOUTUBE">
      <main *ngIf="!loading; else loadingTmpl">
        <div *ngIf="data; else emptyTmpl" class="row">
          <div class="item">
            <div class="img-wrap">
              <div
                class="image-container"
                [ngStyle]="{
                  'background-image': 'url(' + data.mediaUrl + ')'
                }"
              ></div>
              <div class="h-100 d-flex align-items-end">
                <a
                  href="{{ data.postUrl }}"
                  target="_blank"
                  class="btn btn-outline-primary mx-3 my-2"
                  >{{ 'go-content' | transloco }}</a
                >
              </div>
            </div>
            <p>
              {{ 'enums.post-types.' + postType | transloco }} -
              {{ data.caption }}
            </p>
          </div>
        </div>
        <ng-template #emptyTmpl>
          <div *ngIf="channel === Channels.INSTAGRAM" class="no-story">
            <img src="assets/images/empty_preview.png" alt="empty" />
            <h5>{{ 'offers.no-preview-msg' | transloco }}</h5>
            <p>
              {{ 'offers.no-preview-msg-long' | transloco }}
            </p>
          </div>
        </ng-template>
      </main>
    </div>
    <ng-template #loadingTmpl>
      <slice-loader class="slc-fluid-box"></slice-loader>
    </ng-template>
  </div>
</p-dialog>

<ng-template #errorView>
  <div class="my-5">
    <p>{{ 'offers.no-preview-msg' | transloco }}</p>
  </div>
</ng-template>
