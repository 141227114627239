import { Injectable } from '@angular/core';

import { RoleMenuEnum, RolePermissionService } from '@slice/role-user';
import { chain } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '@slice-env/environment';

import { CAMPAIGN_LIST_SUB_ROUTES } from '@slice-shared/modules/campaign-list/enums/sub-routes';
import { CampaignModel } from '@slice-interfaces/campaign-status/campaign-status';
import { MenuItem } from '@slice-interfaces/navigation/menu-item';
import { PAYMENT_HISTORY } from '@slice-enums/bulk-payment/campaign-enums';
import { CampaignModelStatus } from '@slice-enums/campaign-status';
import { AgencySignatureTabs } from '@slice-enums/e-signature/signature-tabs';
import { ContractTypesEnum } from '@slice-enums/e-signature/xpress-contract.enum';
import { E_ROUTES } from '@slice-enums/routes.enum';
import { UserTypes } from '@slice-enums/user-types.enum';

import { CampaignHttpService } from './campaign-http.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AppMenuStateService {
  private updateAppMenuCampaigns$ = new Subject<void>();
  private campaigns$ = new BehaviorSubject<Array<CampaignModel>>([]);
  private items$ = new BehaviorSubject<Array<MenuItem>>([]);
  private bottomItems$ = new BehaviorSubject<Array<MenuItem>>([]);
  public campaignStatuses: Record<string, Array<CampaignModelStatus>> = {};
  public openingCampaignId$ = new BehaviorSubject<string | undefined>(
    undefined,
  );
  constructor(
    private campaignHttpS: CampaignHttpService,
    private roleService: RolePermissionService,
    private navigationS: NavigationService,
  ) {
    // ? maybe init update by user type
    // this.initUpdateByCampaigns();
  }

  getContactUsPath() {
    return this.navigationS.getFullSegmentList(E_ROUTES.CONTACT_US);
  }

  getToolsMenuItem(): MenuItem {
    return {
      id: E_ROUTES.TOOLS,
      titleKey: 'menu.tools.title',
      route: E_ROUTES.TOOLS,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.TOOLS),
      childs: [
        {
          id: E_ROUTES.AGENCY_INTEGRATION,
          titleKey: 'menu.integration.title',
          route: E_ROUTES.AGENCY_INTEGRATION,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.AGENCY_INTEGRATION,
          ),
        },
        {
          id: E_ROUTES.AGENCY_CALCULATOR,
          titleKey: 'menu.cost-estimator.title',
          route: E_ROUTES.AGENCY_CALCULATOR,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.AGENCY_CALCULATOR,
          ),
        },
        {
          id: E_ROUTES.AGENCY_QUOTATION,
          titleKey: 'menu.quotation.title',
          route: E_ROUTES.AGENCY_QUOTATION,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.AGENCY_QUOTATION,
          ),
        },
        {
          id: E_ROUTES.AGENCY_ENGAGEMENT,
          titleKey: 'menu.scrapper.title',
          route: E_ROUTES.AGENCY_ENGAGEMENT,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.AGENCY_ENGAGEMENT,
          ),
        },
      ],
    };
  }

  actionUpdateByCampaigns(): void {
    this.updateAppMenuCampaigns$.next();
  }

  setItems(list: Array<MenuItem>): void {
    this.items$.next(list);
  }

  selectItems(): Observable<Array<MenuItem>> {
    return this.items$.asObservable();
  }

  selecBottomItems(): Observable<Array<MenuItem>> {
    return this.bottomItems$.asObservable();
  }

  private initUpdateByCampaigns(): void {
    this.updateAppMenuCampaigns$
      .asObservable()
      .pipe(
        switchMap(() =>
          this.campaignHttpS.getCampaigns(
            {
              page: 0,
              size: 1000,
              status: [CampaignModelStatus.IN_PROGRESS],
            },
            environment.mocks?.agency.appMenu.getCampaigns,
          ),
        ),
      )
      .subscribe(res => this.campaigns$.next(res?.content || []));
  }

  selectAgencyCampaigns(): Observable<Array<CampaignModel>> {
    return this.campaigns$.asObservable();
  }

  initMenu(userType: UserTypes, campaigns?: Array<MenuItem>): void {
    let items: Array<MenuItem>;
    if (userType === UserTypes.CREATOR) {
      items = [
        this.getHomeItem(),
        this.getCreatorMediaKitItem(),
        this.creatorCampaign(),
        this.wallet(),
        this.getClientAnalyticsItem(),
        this.getCreatorAcademy(),
      ];
    } else if (userType === UserTypes.BRAND) {
      items = [
        this.getHomeItem(),
        ...(campaigns ? campaigns : []),
        this.getContentApprovalsItem(),
        this.getReportsItem(),
      ];
    } else if (userType === UserTypes.MANAGER) {
      items = [
        this.getHomeItem(),
        this.getOffersCreatorItem(),
        this.getClientRosterItem(),
        this.getContentCalendarItem(),
        this.getRevenueItem(),
        this.getInvoicingItem(),
      ];
    } else if (userType === UserTypes.ADMIN) {
      items = [
        this.getHomeItem(),
        this.getClientsItem(),
        this.getCampaignsItem(),
        this.getExcoItem(),
      ];
    } else if (userType === UserTypes.AGENCY) {
      items = [this.getHomeItem()];

      if (
        this.roleService.checkUserPermission(RoleMenuEnum.invite_creator) ||
        this.roleService.checkUserPermission(RoleMenuEnum.invite_by_email)
      ) {
        // items.push(this.getDiscoverCreatorsItem()); /* hide menu requested by jesse */
      }

      if (this.roleService.checkUserPermission(RoleMenuEnum.reporting)) {
        items.push(this.campaignsMenu());
      }
      if (this.roleService.checkUserPermission(RoleMenuEnum.payment)) {
        items.push(this.directPaymentsMenu());
      }
      items.push(this.creatorInfoMenu());

      if (this.roleService.checkUserPermission(RoleMenuEnum.payment)) {
        items.push(this.transactionRecordsMenu());
      }
    } else {
      items = [];
    }
    this.items$.next(items);
  }

  initBottomMenu(userType: UserTypes): void {
    let items: Array<MenuItem>;
    if (userType === UserTypes.AGENCY) {
      items = [this.getToolsMenuItem()];
    } else {
      items = [];
    }
    this.bottomItems$.next(items);
  }

  updateMenuByCampaigns(userType: UserTypes, list: Array<CampaignModel>): void {
    const grouped = chain(list)
      .groupBy('brandName')
      .map((value, key) => ({ brandName: key, campaigns: value }))
      .value();
    const menuItems: Array<MenuItem> = grouped.map(brand => {
      this.campaignStatuses[brand.brandName] = [
        CampaignModelStatus.REPORT_CREATED,
      ];
      return {
        id: brand.brandName,
        title: brand.brandName,
        isBrand: true,
        urlSegments: this.navigationS.getFullSegmentList(
          userType === UserTypes.AGENCY
            ? E_ROUTES.CAMPAIGN_DETAILS
            : E_ROUTES.OFFER,
        ),
        isExistHiddenItems:
          brand.campaigns.filter(
            i =>
              i.status === CampaignModelStatus.BRAND_APPROVAL_PENDING ||
              i.status === CampaignModelStatus.PAYMENT_PENDING ||
              i.status === CampaignModelStatus.PAID ||
              i.status === CampaignModelStatus.IN_PROGRESS,
          ).length !== brand.campaigns.length,
        childs: brand.campaigns.map(camp => {
          const res = {
            id: camp.campaignId,
            title: camp.name,
            campaign: camp,
            isHidden:
              camp.status !== CampaignModelStatus.BRAND_APPROVAL_PENDING &&
              camp.status !== CampaignModelStatus.PAYMENT_PENDING &&
              camp.status !== CampaignModelStatus.PAID &&
              camp.status !== CampaignModelStatus.IN_PROGRESS,
            urlSegments:
              userType === UserTypes.AGENCY
                ? this.navigationS.getPathByCampaignStatusForBrand({
                    campaignId: camp.campaignId,
                    status: camp.status,
                  }).urlSegments
                : [
                    ...this.navigationS.getFullSegmentList(E_ROUTES.OFFER),
                    camp.campaignId,
                  ],
          };
          return res;
        }),
      };
    });
    this.initMenu(userType, menuItems);
  }

  private getOffersCreatorItem(): MenuItem {
    return {
      id: E_ROUTES.OFFERS,
      titleKey: 'menu.offers.title',
      route: E_ROUTES.OFFERS,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.OFFERS),
    };
  }

  private getClientRosterItem(): MenuItem {
    return {
      id: E_ROUTES.CLIENT_ROSTER,
      titleKey: 'menu.client-roster.title',
      route: E_ROUTES.CLIENT_ROSTER,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.CLIENT_ROSTER),
    };
  }

  private getClientAnalyticsItem(): MenuItem {
    return {
      id: E_ROUTES.ANALYTICS,
      titleKey: 'menu.analytics.title',
      route: E_ROUTES.ANALYTICS,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.ANALYTICS),
    };
  }

  private getCreatorMediaKitItem(): MenuItem {
    return {
      id: E_ROUTES.RATE_CARD,
      titleKey: 'menu.rate-card.title',
      route: E_ROUTES.RATE_CARD,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.RATE_CARD),
    };
  }

  private getHomeItem(): MenuItem {
    return {
      id: E_ROUTES.HOME,
      titleKey: 'menu.home.title',
      route: E_ROUTES.HOME,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.HOME),
    };
  }

  private getContentCalendarItem(): MenuItem {
    return {
      id: E_ROUTES.CONTENT_CALENDAR,
      titleKey: 'menu.content-calendar.title',
      route: E_ROUTES.CONTENT_CALENDAR,
      urlSegments: this.navigationS.getFullSegmentList(
        E_ROUTES.CONTENT_CALENDAR,
      ),
    };
  }

  private getContentApprovalsItem(): MenuItem {
    return {
      id: E_ROUTES.CONTENT_APPROVALS,
      titleKey: 'menu.content-approvals.title',
      route: E_ROUTES.CONTENT_APPROVALS,
      urlSegments: this.navigationS.getFullSegmentList(
        E_ROUTES.CONTENT_APPROVALS,
      ),
    };
  }

  private getExcoItem(): MenuItem {
    return {
      id: E_ROUTES.EXCO,
      titleKey: 'menu.exco.title',
      route: E_ROUTES.EXCO,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.EXCO),
    };
  }

  private getDiscoverCreatorsItem(): MenuItem {
    return {
      id: E_ROUTES.DISCOVER_CREATORS,
      titleKey: 'menu.creator-discovery.title',
      route: E_ROUTES.DISCOVER_CREATORS,
      urlSegments: this.navigationS.getFullSegmentList(
        E_ROUTES.DISCOVER_CREATORS,
      ),
    };
  }

  private getReportsItem(): MenuItem {
    return {
      id: E_ROUTES.REPORTS,
      titleKey: 'menu.campaign.title',
      route: E_ROUTES.REPORTS,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.REPORTS),
    };
  }

  private getPaymentOptionsItem(): MenuItem {
    return {
      id: E_ROUTES.PAYMENT_OPTIONS,
      titleKey: 'menu.payment-options.title',
      route: E_ROUTES.PAYMENT_OPTIONS,
      urlSegments: this.navigationS.getFullSegmentList(
        E_ROUTES.PAYMENT_OPTIONS,
      ),
    };
  }

  private getRevenueItem(): MenuItem {
    return {
      id: E_ROUTES.REVENUE,
      titleKey: 'menu.revenue.title',
      route: E_ROUTES.REVENUE,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.REVENUE),
    };
  }

  private getInvoicingItem(): MenuItem {
    return {
      id: E_ROUTES.INVOICING,
      titleKey: 'menu.invoicing.title',
      route: E_ROUTES.INVOICING,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.INVOICING),
    };
  }

  private getClientsItem(): MenuItem {
    return {
      id: E_ROUTES.CLIENTS,
      titleKey: 'menu.clients.title',
      route: E_ROUTES.CLIENTS,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.CLIENTS),
    };
  }

  private getCampaignsItem(): MenuItem {
    return {
      id: E_ROUTES.CAMPAIGNS,
      titleKey: 'menu.campaigns.title',
      route: E_ROUTES.CAMPAIGNS,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.CAMPAIGNS),
    };
  }

  private getContractSignMenu(withDivider = false): MenuItem {
    return {
      id: E_ROUTES.CONTRACT_SIGNATURE,
      isTopDivider: withDivider,
      titleKey: 'menu.contract-sign.title',
      route: E_ROUTES.CONTRACT_SIGNATURE,
      urlSegments: this.navigationS.getFullSegmentList(
        E_ROUTES.CONTRACT_SIGNATURE,
      ),
    };
  }

  private getPitchingItem(): MenuItem {
    return {
      id: E_ROUTES.PITCHING,
      titleKey: 'menu.pitching.title',
      route: E_ROUTES.PITCHING,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.PITCHING),
    };
  }

  private getPaymentItem(): MenuItem {
    return {
      id: E_ROUTES.PAYMENT,
      titleKey: 'menu.payment.title',
      route: E_ROUTES.PAYMENT,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.PAYMENT),
    };
  }

  private getCreatorAcademy(): MenuItem {
    return {
      id: E_ROUTES.ACADEMY,
      titleKey: 'menu.creator-academy.title',
      route: E_ROUTES.ACADEMY,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.ACADEMY),
    };
  }

  private wallet(): MenuItem {
    return {
      id: E_ROUTES.WALLET,
      titleKey: 'menu.digital-wallet.title',
      route: E_ROUTES.WALLET,
      showAllItems: true,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.WALLET),
      childs: [
        {
          id: E_ROUTES.WALLET_INVOICE,
          titleKey: 'menu.invoice.title',
          route: E_ROUTES.WALLET_INVOICE,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.WALLET_INVOICE,
          ),
        },
        {
          id: E_ROUTES.WALLET_BALANCE,
          titleKey: 'menu.balance.title',
          route: E_ROUTES.WALLET_BALANCE,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.WALLET_BALANCE,
          ),
        },
        {
          id: E_ROUTES.WALLET_PAY_DETAIL,
          titleKey: 'menu.payment-detail.title',
          route: E_ROUTES.WALLET_PAY_DETAIL,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.WALLET_PAY_DETAIL,
          ),
        },
      ],
    };
  }

  private creatorCampaign(): MenuItem {
    return {
      id: E_ROUTES.CREATOR_CAMPAIGN,
      titleKey: 'menu.campaign.title',
      route: E_ROUTES.CREATOR_CAMPAIGN,
      showAllItems: true,
      urlSegments: this.navigationS.getFullSegmentList(
        E_ROUTES.CREATOR_CAMPAIGN,
      ),
      childs: [
        {
          id: E_ROUTES.CREATOR_CAMPAIGN_CAMPAIGN,
          titleKey: 'menu.campaign.title',
          route: E_ROUTES.CREATOR_CAMPAIGN_CAMPAIGN,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.CREATOR_CAMPAIGN_CAMPAIGN,
          ),
        },
        {
          id: E_ROUTES.CREATOR_CAMPAIGN_CONTRACT,
          titleKey: 'menu.campaign-contract.title',
          route: E_ROUTES.CREATOR_CAMPAIGN_CONTRACT,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.CREATOR_CAMPAIGN_CONTRACT,
          ),
        },
      ],
    };
  }

  private campaignsMenu(): MenuItem {
    return {
      id: E_ROUTES.REPORTS_HEADER,
      titleKey: 'Campaigns',
      route: E_ROUTES.REPORTS_HEADER,
      showAllItems: true,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.REPORTS_HEADER),
      childs: [
        {
          id: E_ROUTES.REPORTS,
          titleKey: 'Campaign Reports',
          route: E_ROUTES.REPORTS,
          urlSegments: [
            ...this.navigationS.getFullSegmentListSubRoute(E_ROUTES.REPORTS),
            CAMPAIGN_LIST_SUB_ROUTES.active,
          ],
        },
        {
          id: E_ROUTES.BULK_PAYMENT_CAMPAIGN,
          titleKey: 'Campaign Payments',
          route: E_ROUTES.BULK_PAYMENT_CAMPAIGN,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.BULK_PAYMENT_CAMPAIGN,
          ),
        },
        {
          id: E_ROUTES.CONTRACT_SIGNATURE,
          titleKey: 'Campaign Contracts',
          route: E_ROUTES.CONTRACT_SIGNATURE,
          urlSegments: [
            ...this.navigationS.getFullSegmentListSubRoute(
              E_ROUTES.CONTRACT_SIGNATURE,
            ),
            AgencySignatureTabs.Contract,
          ],
          queryParams: {
            type: ContractTypesEnum.CAMPAIGN,
          },
        },
      ],
    };
  }

  private directPaymentsMenu(): MenuItem {
    return {
      id: E_ROUTES.DIRECT_PAYMENTS,
      titleKey: 'Direct Payments',
      route: E_ROUTES.DIRECT_PAYMENTS,
      urlSegments: this.navigationS.getFullSegmentList(
        E_ROUTES.DIRECT_PAYMENTS,
      ),
      childs: [
        {
          id: E_ROUTES.BULK_PAYMENT_CUSTOM_PAYMENT,
          titleKey: 'Bank Payment',
          route: E_ROUTES.BULK_PAYMENT_CUSTOM_PAYMENT,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.BULK_PAYMENT_CUSTOM_PAYMENT,
          ),
        },
        {
          id: E_ROUTES.CONTRACT_SIGNATURE,
          titleKey: 'Individual Contract',
          route: E_ROUTES.CONTRACT_SIGNATURE,
          urlSegments: [
            ...this.navigationS.getFullSegmentListSubRoute(
              E_ROUTES.CONTRACT_SIGNATURE,
            ),
            AgencySignatureTabs.Contract,
          ],
          queryParams: {
            type: ContractTypesEnum.STANDALONE,
          },
        },
      ],
    };
  }

  private creatorInfoMenu(): MenuItem {
    return {
      id: E_ROUTES.CREATOR_INFO,
      titleKey: 'Creator Info',
      route: E_ROUTES.CREATOR_INFO,
      showAllItems: true,
      urlSegments: this.navigationS.getFullSegmentList(E_ROUTES.CREATOR_INFO),
      childs: [
        {
          id: E_ROUTES.CLIENT_ROSTER,
          titleKey: 'menu.client-roster.title',
          route: E_ROUTES.CLIENT_ROSTER,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.CLIENT_ROSTER,
          ),
        },
        {
          id: E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY,
          titleKey: 'Creator Earnings',
          route: E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY,
          urlSegments: [
            ...this.navigationS.getFullSegmentListSubRoute(
              E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY,
            ),
            PAYMENT_HISTORY.earnings,
          ],
        },
      ],
    };
  }

  private transactionRecordsMenu(): MenuItem {
    return {
      id: E_ROUTES.TRANSACTION_RECORDS,
      titleKey: 'Transaction Records',
      route: E_ROUTES.TRANSACTION_RECORDS,
      showAllItems: true,
      urlSegments: this.navigationS.getFullSegmentList(
        E_ROUTES.TRANSACTION_RECORDS,
      ),
      childs: [
        {
          id: E_ROUTES.BULK_PAYMENT_BALANCE,
          titleKey: 'menu.digital-wallet.title',
          route: E_ROUTES.BULK_PAYMENT_BALANCE,
          urlSegments: this.navigationS.getFullSegmentListSubRoute(
            E_ROUTES.BULK_PAYMENT_BALANCE,
          ),
        },
        {
          id: E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY,
          titleKey: 'Payment History',
          route: E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY,
          urlSegments: [
            ...this.navigationS.getFullSegmentListSubRoute(
              E_ROUTES.BULK_PAYMENT_PAYMENT_HISTORY,
            ),
            PAYMENT_HISTORY.payment,
          ],
        },
      ],
    };
  }
}
