import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { createHttpParams } from '@slc-libs/helpers';
import { HttpGeneralResponse } from '@slc-libs/interfaces';

import {
  MekariSetupModel,
  MekariWidgetModel,
} from '@slice-interfaces/integration/mekari-setup.model';
import { SupportedIntegrationModel } from '@slice-interfaces/integration/supported-integration.model';
import { Select } from '@slice-interfaces/select/select';
import { MekariJurnalTransaction } from '@slice-enums/integration/mekari-integration.enum';

import {
  AdditionalFormBuy,
  AdditionalFormExpense,
  FormMekariPaymentS,
  OptionsJurnalCategory,
} from '../_const/form-mekari-jurnal';

@Injectable({
  providedIn: 'root',
})
export class MekariHttpService {
  constructor(private http: HttpClient) {}

  getExternalIntegrationList(): Observable<Array<SupportedIntegrationModel>> {
    return this.http.get<Array<SupportedIntegrationModel>>(
      '/api/v1/agencies/journal/status/',
    );
  }
  getExternalIntegrationStatus(vendor: string): Observable<string> {
    return this.http.get<string>(`/api/v1/agencies/journal/status/${vendor}`);
  }

  saveMekariToken(token: string): Observable<HttpGeneralResponse> {
    return this.http.post<HttpGeneralResponse>(
      `/api/v1/agencies/journal/mekari/connect?token=${token}`,
      {},
    );
  }
  removeMekariToken(): Observable<HttpGeneralResponse> {
    return this.http.delete<HttpGeneralResponse>(
      `/api/v1/agencies/journal/mekari/disconnect`,
    );
  }

  getJurnalConfig(): Observable<MekariSetupModel> {
    return this.http.get<MekariSetupModel>(
      '/api/v1/agencies/journal/mekari/config',
    );
  }
  saveJurnalConfig(config: MekariSetupModel) {
    return this.http.post<HttpGeneralResponse>(
      '/api/v1/agencies/journal/mekari/config',
      config,
    );
  }

  getJurnalWidget(): Observable<MekariWidgetModel[]> {
    return this.http.get<MekariWidgetModel[]>(
      '/api/v1/agencies/journal/mekari/campaign',
    );
  }

  fetchJournalForm(
    fieldAPI: string,
    fieldParams: Record<string, any>,
  ): Observable<any> {
    if (fieldAPI === 'mekari-form') {
      if (fieldParams.value === 'PER_TRANSACTION') {
        return of(FormMekariPaymentS);
      } else if (fieldParams.value === 'PER_CREATOR') {
        return of(FormMekariPaymentS);
      } else if (fieldParams.value === 'PURCHASE') {
        return of(AdditionalFormBuy);
      } else if (fieldParams.value === 'EXPENSE') {
        return of(AdditionalFormExpense);
      } else {
        console.error('form url', fieldAPI + '?', JSON.stringify(fieldParams));
        return of([]);
      }
    } else {
      return this.http.get<any>(fieldAPI, {
        params: createHttpParams(fieldParams),
      });
    }
  }
  fetchJournalOptions(form: MekariJurnalTransaction): Observable<Select[]> {
    if (form === MekariJurnalTransaction.JURNAL_CATEGORY) {
      return of(OptionsJurnalCategory);
    } else {
      return this.http.get<Select[]>(
        `/api/v1/agencies/journal/mekari/dropdown?type=${form}`,
      );
    }
  }
}
